export const routes = (params?: string) => ({
    home: '/',
    signup: `/signup`,
    login:'/login',
    overview: '/overview',
    media: '/media',
    campaign: '/campaign',
    createCampaign: '/create-campaign',
    cluster: '/cluster',
    createCluster: '/create-cluster',
    screens: '/screens',
})