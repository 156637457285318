import { TInputProps } from "./input.types"
import styles from './input.module.scss'
import { useState } from "react"
import classNames from "classnames"
import { Text, TextVariantsEnum } from "../Text"

export const InputComponent: React.FC<TInputProps> = ({
    label,
    type = 'text',
    placeholder,
    value,
    onChange,
    width,
    disabled = false,
    leftBlock,
    leftBlockClass,
    leftBlockClickHandler,
    rightBlock,
    rightBlockClass,
    rightBlockClickHandler
}) => {
    const [isFocused, setIsFocused] = useState(false)
    const style = { width }

    const inputClass = classNames(styles.input, {
        [styles[`input_focused`]]: isFocused
    })

    const handleOnFocus = () => {
        setIsFocused(true)
    }
    const handleOnBlur = () => {
        setIsFocused(false)
    }

    return (
        <>
            {label && <Text
                variant={TextVariantsEnum.Text_md}
            >{label}</Text>
            }
            <div className={styles.inputWrapper} style={style}>
                {leftBlock && <div
                    className={leftBlockClass}
                    onClick={leftBlockClickHandler}
                >{leftBlock}</div>}
                <input
                    className={inputClass}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    disabled={disabled}
                />
                {rightBlock && <div
                    className={rightBlockClass}
                    onClick={rightBlockClickHandler}>
                    {rightBlock}
                </div>}
            </div>
        </>
    )
}