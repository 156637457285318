import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import styles from "./CreateCampaign.module.scss";
import { builderNameSectionMode } from "../../../features/BuilderNameSection/BuilderNameSection.types";
import { routes } from "../../../routing/routes";
import { CreateHeader } from "../../../features/CreateHeader/CreateHeader.component";
import Button from "../../../components/Button/Button";
import { ButtonVariantEnum } from "../../../components/Button/button.types";
import { Stepper } from "../../../components/Stepper/Stepper.component";
import { SelectScreen } from "./SelectScreen/SelectScreenCluster.component";
import { SelectMedia } from "./SelectMedia/SelectMedia.component";
import { useCampaignsActions } from "../../../store/campaigns/useCampaignsActions";
import { ScheduleCampaign } from "./ScheduleCampaign/ScheduleCampaign.component";
import { useCampaignsSelector } from "../../../store/campaigns/useCampaignsSelector";
import {useClustersSelector} from "../../../store/cluster/useClusterSelector";
import {useLoginSelector} from "../../../store/login/useLoginSelectors";

export const CreateCampaign: React.FC = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [inputValue, setInputValue] = useState("");

  const { resetCampaigns } = useCampaignsActions();
  const {
    selectedMedia,
    selectedScreens,
    selectedDates,
    selectedSlots,
    SelectedCampaignType,
  } = useCampaignsSelector();

  const { selectedClusters } = useClustersSelector();
  const { userData } = useLoginSelector();

  useEffect(() => {
    return () => {
      resetCampaigns();
    };
  }, []);

  const onInputChange = (value: string) => {
    setInputValue(value);
  };

  const onCloseClick = () => {
    localStorage.removeItem('SelectedCampaignType');
    navigate(routes().campaign);
  };

  const onStepClick = (step: number) => {
    setCurrentStep(step);
  };

  const onNextClick = () => {
    if (currentStep < 3) setCurrentStep(currentStep + 1);
  };
  const onBackClick = () => {
    if (currentStep === 1) onCloseClick();
    else setCurrentStep(currentStep - 1);
  };
  const onDraftSave = () => {
    if(inputValue?.length === 0) {
      console.log('Disable')
    }
    else {
      console.log('Save it');
    }
  };

  const validateScreen = useMemo(() => {
    if (!inputValue) return false;
    switch (currentStep) {
      case 1:
        return !!selectedMedia;
      case 2:
        return SelectedCampaignType === 'myScreens' ? !!selectedScreens?.length : !!selectedClusters?.length;
      case 3:
        return !!selectedDates.length && !!selectedSlots.length;
      default:
        return true;
    }
  }, [inputValue,currentStep,selectedMedia,selectedScreens, selectedClusters, selectedDates, selectedSlots ]);

  const steps = [
    { label: "Setup", component: SelectMedia },
    {
        label: SelectedCampaignType === 'myScreens' ? 'Select Screens' : 'Select Cluster',
        component: SelectScreen 
    },
    { label: "Schedule", component: ScheduleCampaign },
];


  const CurrentComponent = useMemo(() => {
    return steps?.[currentStep - 1].component;
  }, [currentStep]);

  const footer = (
    <div className={styles.footer}>
      <Button
        variant={ButtonVariantEnum.secondary}
        text="Back"
        onClick={onBackClick}
        height={"32px"}
        width={"78px"}
      />
      <Button
          variant={ButtonVariantEnum.secondary}
          text="Save As Draft"
          onClick={onDraftSave}
          height={"32px"}
          width={"120px"}
          disabled={inputValue?.length === 0}
      />
      <Button
        variant={ButtonVariantEnum.primary}
        text={currentStep === 3 ? "Publish" : "Next"}
        onClick={onNextClick}
        height={"32px"}
        width={"78px"}
        disabled={!validateScreen}
      />
    </div>
  );
  
  return (
        <div>
          <CreateHeader
            headerText="Create New Campaign"
            name={inputValue}
            setName={onInputChange}
            nameFieldPlaceholder={"Enter Campaign Name"}
            onCloseClick={onCloseClick}
            mode={builderNameSectionMode.CREATE}
            footer={footer}
          />
          <div className={styles.currentCompWrapper}>
            <Stepper
              steps={steps}
              currentStep={currentStep}
              // onStepClick={onStepClick}
            />
            <CurrentComponent />
          </div>
        </div>
  );
};
