import { call, takeLatest, all, put } from "redux-saga/effects";
import { TActionCreateClusterPayload, TActionGetClusterListPayload } from "./cluster.types";
import { actionCreateCluster, actionCreateClusterFailed, actionCreateClusterSuccess, actionFetchClusters, actionFetchClustersFailed, actionFetchClustersSuccess, actionFetchScreensList, actionFetchScreensListFailed, actionFetchScreensListSuccess } from "./cluster.reducer";
import { ClustersService } from "../../services/clusters/ClustersService";

function* fetchClusterList({payload}: TActionGetClusterListPayload): any{
    try {
        const data= yield call(ClustersService.getClusters, payload)                       
        yield put(actionFetchClustersSuccess({data: data.response.details,
            totalPages: data.response.totalPages
        }))
    } catch (error) {
        console.log(error);
        yield put(actionFetchClustersFailed())
    }
}

function* fetchScreensList({payload}: TActionGetClusterListPayload): any{
    try {
        const data= yield call(ClustersService.getScreensList, {page: payload.page})                       
        yield put(actionFetchScreensListSuccess({data: data.response.details,
            totalPages: data.response.totalPages
        }))
    } catch (error) {
        console.log(error);
        yield put(actionFetchScreensListFailed())
    }
}

function* createClusterSaga({payload}: TActionCreateClusterPayload): any{
    try {
        const data= yield call(ClustersService.createCluster, payload)                       
        yield put(actionCreateClusterSuccess(data.response.details))
    } catch (error) {
        console.log(error);
        yield put(actionCreateClusterFailed())
    }
}


export function* clustersWatcher() {
    yield all([
        takeLatest(actionFetchClusters.type, fetchClusterList),
        takeLatest(actionFetchScreensList.type, fetchScreensList),
        takeLatest(actionCreateCluster.type, createClusterSaga)
    ])
}