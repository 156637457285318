import { format, formatDistanceToNow } from 'date-fns';

export const dateTimeFormatter = (date: string | Date | number) => {
  if (!date) return '-';

  const currentDate = new Date(date);

  return `${format(currentDate, 'MMM dd, yyyy')}`;
};

export const dateFormatter = (date: string | Date | number) => {
  try {
    if (!date) return '-';
    const currentDate = new Date(date);
    return `${format(currentDate, 'dd MMM yyyy')}`;
  } catch (err) {
    return '-';
  }
};

export const epochDateFormatter = (
  date: number,
  formatOfTheDate = 'MMMM dd, yyyy'
) => {
  if (!date) return '-';
  const currentDate = new Date(date * 1000);
  return `${format(currentDate, formatOfTheDate)}`;
};

export const customDateFormatter = (
  date: string | Date | number,
  formatOfTheDate: string
) => {
  if (!date) return '-';
  const currentDate = new Date(date);
  return `${format(currentDate, formatOfTheDate)}`;
};

export const relativeTimeObjectFormatter = (dateObject: {
  seconds: number;
  nanos: number;
}) => {
  if (!dateObject || !dateObject.seconds) return '-';

  const { seconds, nanos } = dateObject;
  const dateInMillis = seconds * 1000 + nanos / 1000000;

  const targetDate = new Date(dateInMillis);

  return formatDistanceToNow(targetDate, { addSuffix: true });
};