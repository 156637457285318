import { columnConfigType } from "../../components/Table/Table.types"
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text"
import { TClusterListData } from "../../store/cluster/cluster.types"
import { capitalize } from "../../utils/capitalize"
     
export const ClusterTableColumns = (
    onNameClick: (name: string) => void
): columnConfigType<TClusterListData>[] => [
        {
            id: 'cluster_name',
            name: 'Cluster Name',
            cellRenderer : (data) => (
                <div>
                    <Text 
                    variant = {TextVariantsEnum.Text_md}
                    fontWeight={FontWeightEnum.Normal}
                    color={'neutral-70'}
                    onClick={() => onNameClick(data.name)}
                    >
                        {capitalize(data.name)}
                    </Text>
                </div>
            )   
        },
        {
            id: 'total_screens',
            name: 'Total Screens',
            cellRenderer : (data) => (
                <div>
                    <Text 
                    variant = {TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                        {data.totalScreens}
                    </Text>
                </div>
            )   
        },
    ]