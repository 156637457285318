import React, { useState } from 'react';
import styles from './Dropdown.module.scss'
import { SvgIcon } from '../SvgIcon';
import { Text, TextVariantsEnum } from '../Text';
import classNames from 'classnames';

export const Dropdown = ({ options, placeholder }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({label: null, value: null});

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdown}>
      <div className={styles.dropdown_header} onClick={toggleDropdown}>
        <Text variant={TextVariantsEnum.Text_sm} color={!selectedOption.label ? 'neutral-50' : 'neutral-70'}> 
        {selectedOption.label ? selectedOption.label : placeholder}</Text>
        <SvgIcon src={'icons/arrow.svg'} rotate={isOpen ? "270" : "90"} size={12} />
      </div>
      {isOpen && (
        <ul style={{listStyle: 'none'}} className={styles.dropdown_list}>
          {options.map((option: any, index: number) => (
            <Text variant={TextVariantsEnum.Text_sm}
              key={index}
              className={styles.dropdown_list_item}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </Text>
          ))}
        </ul>
      )}
    </div>
  );
};

