import { createSlice } from '@reduxjs/toolkit';
import { requestStatusEnum } from '../store.types';
import { TActionUploadMediaData, TMediaReducer } from './media.types';


const initialState: TMediaReducer = {
    media: {
      data: null,
      status: requestStatusEnum.INITIAL
    },
    mediaDataUploaded:{
      file: null,
      mediaName: '',
      tags: '',
    },
    mediaUpload:{
      data: null,
      status: requestStatusEnum.INITIAL
    }
};

const mediaReducer = createSlice({
  name: 'media',
  initialState,
  reducers: {
    actionGetMediaStream(state, {payload}){
      state.media.status = requestStatusEnum.PROGRESS
    },
    actionGetMediaStreamSuccess(state, {payload}){
      state.media.status = requestStatusEnum.SUCCESS
      state.media.data = payload
    },
    actionGetMediaStreamFailed(state){
      state.media.status = requestStatusEnum.FAILED
      state.media.data = null
    },

    actionSetMediaUploadData(state, {payload}){
      state.mediaDataUploaded = {
        ...state.mediaDataUploaded,
        ...payload
      }
    },
    actionResetMediaUploadData(state){
      state.mediaDataUploaded = initialState.mediaDataUploaded
    },
    actionUploadMedia(state, {payload}){
      state.mediaUpload.status = requestStatusEnum.PROGRESS
    },
    actionUploadMediaSuccess(state, {payload}){
      state.mediaUpload.status = requestStatusEnum.SUCCESS
      state.mediaUpload.data = payload
    },
    actionUploadMediaFailed(state){
      state.mediaUpload.status = requestStatusEnum.FAILED
      state.mediaUpload.data = null
    },
  },
})

export const {
  actionGetMediaStream,
  actionGetMediaStreamSuccess,
  actionGetMediaStreamFailed,
  actionUploadMedia,
  actionUploadMediaSuccess,
  actionUploadMediaFailed,
  actionSetMediaUploadData,
  actionResetMediaUploadData
} = mediaReducer.actions;

export default mediaReducer.reducer

