import {all, fork} from 'redux-saga/effects'
import { loginWatcher } from './login/login.saga'
import { campaignsWatcher } from './campaigns/campaigns.saga'
import { mediaWatcher } from './media/media.saga'
import { clustersWatcher } from './cluster/cluster.saga'

export default function* rootSaga() {
    yield all([
      // auth
      fork(loginWatcher),
      fork(campaignsWatcher),
      fork(mediaWatcher),
      fork(clustersWatcher)
    ])
}