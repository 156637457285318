import Button from "../../../../components/Button/Button";
import {
  ButtonTextSizeEnum,
  ButtonVariantEnum,
} from "../../../../components/Button/button.types";
import { Dropdown } from "../../../../components/Dropdown/Dropdown.component";
import {
  FontWeightEnum,
  Text,
  TextVariantsEnum,
} from "../../../../components/Text";
import styles from "./CreateClusterFilter.module.scss";

export const CreateClusterFilter = () => {
  const options = [
    { label: "Option 1", value: "1" },
    { label: "Option 2", value: "2" },
    { label: "Option 3", value: "3" },
    { label: "Option 4", value: "4" },
    { label: "Option 5", value: "5" },
    { label: "Option 6", value: "6" },
    { label: "Option 7", value: "7" },
  ];

  return (
    <div className={styles.filterWrapper}>
      <div className={styles.filters}>
        <Text
          variant={TextVariantsEnum.Text_sm}
          fontWeight={FontWeightEnum.Normal}
        >
          Filters
        </Text>
        <Dropdown options={options} placeholder="State" />
        <Dropdown options={options} placeholder="City" />
      </div>
      <Button
        iconSize={12}
        text={"Apply"}
        onClick={() => console.log("applied")}
        variant={ButtonVariantEnum.primary}
        size={ButtonTextSizeEnum.md}
        className={styles.applyBtn}
      />
    </div>
  );
};
