import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useCampaignsActions } from "../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../store/campaigns/useCampaignsSelector";
import { requestStatusEnum } from "../../store/store.types";
import { TScreensListData } from "../../store/screens/screens.types";
import styles from "../screens/screens.module.scss";
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text";
import Button from "../../components/Button/Button";
import { ButtonTextSizeEnum, ButtonVariantEnum } from "../../components/Button/button.types";
import { Spinner } from "../../components/Spinner/Spinner.component";
import { Table } from "../../components/Table/Table.component";
import { ScreensTableColumns } from "./screens.config";
import { Modal } from "../../components/Modal";
import { OtpPair } from "../../components/OtpPair";

export const ScreensComponent = () => {
    const [tableData, setTableData] = useState<Array<TScreensListData>>([]);
    const [startRecord, setStartRecord] = useState(0);
    const [isAddScreenModalVisible, setIsAddScreenModalVisible] = useState<boolean>(false);

    const { actionFetchScreensList } = useCampaignsActions();
    const { screensList: { data: screensList, status: screensListStatus, totalPages } } = useCampaignsSelector();

    useEffect(() => {
        actionFetchScreensList({ page: startRecord });
    }, []);

    useEffect(() => {
        if (screensListStatus === requestStatusEnum.SUCCESS && screensList?.length > 0) {
            setTableData(screensList);
        }
    }, [screensListStatus, screensList]);

    const onNameClick = (name: string) => {
        console.log("name", name);
    };

    const handleAddScreenButton = () => {
        setIsAddScreenModalVisible(true);
    };

    const onCloseAddScreenModal = () => {
        setIsAddScreenModalVisible(false);
    };

    const onPaginationClick = (startRecord: number, recordsPerPage: number) => {
        setStartRecord(startRecord);
        actionFetchScreensList({ page: startRecord / recordsPerPage });
    };

    return (
        <div className={styles.screensWrapper}>
            <div className={styles.headerWrapper}>
                <Text
                    variant={TextVariantsEnum.Display_sm}
                    fontWeight={FontWeightEnum.Bold}
                    className={styles.header}
                >
                    Screens
                </Text>
                <Button
                    icon={"./icons/plus.svg"}
                    iconColor="white"
                    iconSize={12}
                    text={"Add Screen"}
                    onClick={handleAddScreenButton}
                    variant={ButtonVariantEnum.primary}
                    size={ButtonTextSizeEnum.md}
                />
            </div>
            {screensListStatus === requestStatusEnum.PROGRESS ? (
                <Spinner />
            ) : (
                <Table
                    data={tableData}
                    columns={ScreensTableColumns(onNameClick)}
                    isLoading={false}
                    totalRecords={totalPages * 20}
                    startRecord={startRecord}
                    recordsPerPage={20}
                    onPaginationClick={onPaginationClick}
                    emptyState={"No Screen Found"}
                />
            )}
            <Modal
                isVisible={isAddScreenModalVisible}
                onClose={onCloseAddScreenModal}
                className={styles.modalClass}
                customStyles={{ maxWidth: '500px', maxHeight: '415px' }}
            >
                <OtpPair
                    title={'Add Screens'}
                    subtitle={'Type your pair code, which you see on your screen now'}
                />
            </Modal>
        </div>
    );
};
