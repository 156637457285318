import {combineReducers} from '@reduxjs/toolkit'
import loginReducer from './login/login.reducer'
import campaignsReducer from './campaigns/campaigns.reducer'
import mediaReducer from './media/media.reducer'
import clustersReducer from './cluster/cluster.reducer'

const rootReducers = combineReducers({
    login: loginReducer,
    campaigns: campaignsReducer,
    media: mediaReducer,
    clusters: clustersReducer
})

export default rootReducers