import { FontWeightEnum, Text, TextVariantsEnum } from "../Text"
import { capitalize } from "../../utils/capitalize"

import styles from './StatusBadge.module.scss'

export enum StatusBadgeEnum {
    SCHEDULED = 'scheduled',
    COMPLETED = 'completed',
    CANCELLED ='cancelled',
    ACTIVE = 'active',
    ONLINE ='online',
    OFFLINE='offline'
}

const getStatusBadgeColor = (status: string) => {
    switch(status){
        case StatusBadgeEnum.SCHEDULED: return 'scheduled'
        case StatusBadgeEnum.ACTIVE: return 'active'
        case StatusBadgeEnum.CANCELLED: return 'error'
        case StatusBadgeEnum.COMPLETED: return 'success'
        case StatusBadgeEnum.ONLINE: return 'success'
        case StatusBadgeEnum.OFFLINE: return 'neutral-20'
    }
}

export const StatusBadge = ({status}: {status: string}) => {
    return (
        <div className={styles.badgeWrapper}>
            <div className={styles.badgeIcon} style={{background: `var(--${getStatusBadgeColor(status)})`}} />
            <Text 
                variant={TextVariantsEnum.Text_md}
                fontWeight={FontWeightEnum.Normal}
                color={'neutral-70'}
            >
                {capitalize(status?.toLowerCase())}
            </Text>
        </div>
    )
}