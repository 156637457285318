import { SvgIcon } from "../../components/SvgIcon"
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text"
import { useEffect } from "react"
import { useNavigate } from "react-router"
import { useLoginSelector } from "../../store/login/useLoginSelectors"
import { useLoginActions } from "../../store/login/useLoginActions"
import { NAV_ITEM_NAME, TNavBarItem, navbarItem } from "./Navbar.utils"
import styles from './Navbar.module.scss'
import classNames from "classnames"

export const NavbarComponent = () => {
    const navigate = useNavigate()
    const { userData } = useLoginSelector()
    const { actionSetActiveNav } = useLoginActions()
    const currentNav = sessionStorage.getItem('currentNav') as NAV_ITEM_NAME || NAV_ITEM_NAME.OVERVIEW;

    useEffect(() => {
        actionSetActiveNav(currentNav as NAV_ITEM_NAME)
    }, [currentNav, actionSetActiveNav])

    const handleActiveNav = (navData: TNavBarItem) => {
        sessionStorage.setItem('currentNav', navData.name);
        actionSetActiveNav(navData.name);
        navigate(navData.route);
    }

    return (
        <div className={styles.navbarWrapper}>
            <img src={'./images/logo_white.svg'} width={135} alt="Logo" />
            <div className={styles.headerLinks}>
                {navbarItem(currentNav).map((item, index) => (
                    <div key={index} className={classNames(styles.item)}
                         onClick={() => handleActiveNav(item)}
                    >
                        <SvgIcon
                            className={classNames({ [styles.activeIcon]: item.active })}
                            size={item.active ? 28 : 14}
                            src={item.icon}
                            defaultStoke
                        />
                        <Text
                            variant={TextVariantsEnum.Text_sm}
                            fontWeight={FontWeightEnum.Normal}
                            color={item.active ? 'white' : 'neutral-30'}
                        >
                            {item.name}
                        </Text>
                    </div>
                ))}
            </div>
            <div>
                <Text variant={TextVariantsEnum.Text_sm} color="white">{userData.name}</Text>
            </div>
        </div>
    )
}
