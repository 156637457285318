import { all, call, put, takeLatest } from "redux-saga/effects";
import { MediasService } from "../../services/media/MediaService";
import {
  actionGetMediaStream,
  actionGetMediaStreamFailed,
  actionGetMediaStreamSuccess,
  actionUploadMedia,
  actionUploadMediaFailed,
  actionUploadMediaSuccess,
} from "./media.reducer";
import { auth } from "../../config/firebase";

const fetchWrapper = async (url: string) => {
  const currentUser = auth.currentUser;
  if (currentUser) {
    const access_token = await currentUser.getIdToken();
    return fetch(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.blob().then((blob) => ({
        blob,
        statusText: response.statusText,
      }));
    });
  }
};

function* fetchVideoUrl(payload: {orgId: string; id: string;}) {
  try {
    const url = `${process.env.REACT_APP_GATEWAY_URL}/public/media/stream/org/${payload.orgId}/media/${payload.id}`;
    // const url = `https://media-management-service-2gcvfph2cq-el.a.run.app/public/media/stream?fileName=${videoId}`;
    const { blob } = yield call(fetchWrapper, url);

    const urlCreator = window.URL || window.webkitURL;
    const videoUrl = urlCreator.createObjectURL(blob);
    return videoUrl;
  } catch (error) {
    console.error("Error fetching video URL:", error);
    return null;
  }
}
function* fetchMediaSaga({ payload }: any): any {
  try {
    const videoUrl = yield call(fetchVideoUrl, payload);

    if (videoUrl) {
      yield put(actionGetMediaStreamSuccess(videoUrl));
    } else {
      yield put(actionGetMediaStreamFailed());
    }
  } catch (error) {
    console.error("Error in fetchMediaSaga:", error);
    yield put(actionGetMediaStreamFailed());
  }
}

function* uploadMediaSaga({ payload }: any): any {
  try {
    const formdata = new FormData();
    formdata.append("file", payload.file);
    formdata.append("userId", payload.userId);
    formdata.append("orgId", payload.orgId);
    formdata.append("name", payload.mediaName);
    formdata.append("tags", payload.tags);
    const response = yield call(MediasService.uploadMedia, formdata);
    console.log("response", response);

    yield put(actionUploadMediaSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(actionUploadMediaFailed());
  }
}

export function* mediaWatcher() {
  yield all([
    takeLatest(actionGetMediaStream.type, fetchMediaSaga),
    takeLatest(actionUploadMedia.type, uploadMediaSaga),
  ]);
}
