import { call, takeLatest, all, put } from "redux-saga/effects";
import { AuthService } from "../../services/user/AuthService";
import { TActionLoginWithEmailPayload } from "./login.types";
import { actionSubmitLogin, actionSubmitLoginFailed, actionSubmitLoginSuccess } from "./login.reducer";
import { auth } from "../../config/firebase";

function* submitEmailLogin({payload}: TActionLoginWithEmailPayload): any{
    try {
        yield call(AuthService.submitEmailLogin, payload)
        const currentUser = auth.currentUser        
        if(currentUser) {
            const userId = currentUser.uid
            const data= yield call(AuthService.loginUpdate, {userId, type: 'CUSTOMER'})               
            yield put(actionSubmitLoginSuccess({data}))
        }
        else{
            throw Error()
        }
    } catch (error) {
        console.log(error);
        yield put(actionSubmitLoginFailed())
    }
}


export function* loginWatcher() {
    yield all([
        takeLatest(actionSubmitLogin.type, submitEmailLogin)
    ])
}