import React from 'react';
import classNames from 'classnames';

import styles from './Spinner.module.scss';

export const Spinner: React.FC<{ rounded?: boolean }> = ({ rounded }) => (
  <div
    className={classNames({
      [styles.spinner]: !rounded,
      [styles.rounded]: rounded,
    })}
  />
);