import React, { useEffect } from 'react';

import styles from './login.module.scss';
import { Text, TextVariantsEnum } from '../../../components/Text';
import { LoginForm } from './loginForm/Form';
import { useLoginSelector } from '../../../store/login/useLoginSelectors';
import { requestStatusEnum } from '../../../store/store.types';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routing/routes';

export const LoginPage: React.FC = () => {
  const navigate = useNavigate()
  const {form: {status}, userData } = useLoginSelector()
  
  useEffect(() => {
    if(status === requestStatusEnum.SUCCESS) {
      sessionStorage.removeItem('currentNav');
      navigate(routes().overview)
      sessionStorage.setItem('userData', JSON.stringify(userData))
    }
  },[status])
  
  return (
    <div className={styles.container}>
      <div className={styles.imagePlaceholder}>
        <img src={'./images/login.svg'} width={'100%'} height={'100%'} />
      </div>
      <div className={styles.loginSection}>
        <div className={styles.logoPlaceholder}>    <img 
            src={'./images/logo.svg'} 
            style={{ maxWidth: '100%', height: 'auto' }} 
            alt="Logo" 
          />  </div>
        <div className={styles.formWrapper}>
          <Text
            className={styles.login_head}
            variant={TextVariantsEnum.Display_sm}
            > 
            Log In
          </Text>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

