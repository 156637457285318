import { createAction, createSlice } from '@reduxjs/toolkit';
import { requestStatusEnum } from '../store.types';
import { TActionGetCampaignsListPayload, TActionGetCampaignsListSuccess, TActionGetMediaLibraryPayload, TActionGetMediaLibrarySuccess, TActionGetScreensListSuccess, TCampaignsReducer } from './campaigns.types';

const initialState: TCampaignsReducer = {
    campaignsList: {
        data: [],
        status: requestStatusEnum.INITIAL
    },
    currentPage: 0,
    mediaLibrary: {
        data: [],
        totalPages: 0,
        status: requestStatusEnum.INITIAL
    },
    SelectedCampaignType: localStorage.getItem('SelectedCampaignType') || '',
    selectedMedia: '',
    screensList: {
        data: [],
        totalPages: 0,
        status: requestStatusEnum.INITIAL
    },
    selectedScreens: [],
    selectedDates: [],
    selectedSlots: [],
    selectedClusterSlots: [],
};

const campaignsReducer = createSlice({
    name: 'campaigns',
    initialState,
    reducers: {
        actionFetchCampaigns(state, { payload }: TActionGetCampaignsListPayload) {
            state.campaignsList.status = requestStatusEnum.PROGRESS;
        },
        actionFetchCampaignsSuccess(state, { payload }: TActionGetCampaignsListSuccess) {
            state.campaignsList.status = requestStatusEnum.SUCCESS;
            state.campaignsList.data = payload.data;
        },
        actionFetchCampaignsFailed(state) {
            state.campaignsList.status = requestStatusEnum.FAILED;
        },
        actionFetchMediaLibrary(state, { payload }: TActionGetMediaLibraryPayload) {
            state.mediaLibrary.status = requestStatusEnum.PROGRESS;
        },
        actionFetchMediaLibrarySuccess(state, { payload }: TActionGetMediaLibrarySuccess) {
            state.mediaLibrary.status = requestStatusEnum.SUCCESS;
            state.mediaLibrary.data = payload.data;
            state.mediaLibrary.totalPages = payload.totalPages;
        },
        actionFetchMediaLibraryFailed(state) {
            state.mediaLibrary.status = requestStatusEnum.FAILED;
            state.mediaLibrary.data = [];
        },
        actionSetSelectedMedia(state, { payload }) {
            state.selectedMedia = payload;
        },
        actionFetchScreensList(state, { payload }: TActionGetCampaignsListPayload) {
            state.screensList.status = requestStatusEnum.PROGRESS;
        },
        actionFetchScreensListSuccess(state, { payload }: TActionGetScreensListSuccess) {
            state.screensList.status = requestStatusEnum.SUCCESS;
            state.screensList.data = payload.data;
            state.screensList.totalPages = payload.totalPages;
        },
        actionFetchScreensListFailed(state) {
            state.screensList.status = requestStatusEnum.FAILED;
            state.screensList.data = [];
        },
        actionSetSelectedScreen(state, { payload }) {
            state.selectedScreens = payload;
        },
        actionSetSelectedDates(state, { payload }) {
            state.selectedDates = payload;
        },
        actionSetSelectedSlots(state, { payload }) {
            state.selectedSlots = payload;
        },
        actionSetSelectedCampaignType(state, { payload }) {
            state.SelectedCampaignType = payload;
            localStorage.setItem('SelectedCampaignType', payload);
        },
        actionAddClusterSlots(state, { payload }){
            state.selectedClusterSlots = payload;
        },
        resetCampaigns() {
            localStorage.removeItem('SelectedCampaignType');
            return initialState;
        },
    },
});

export const {
    actionFetchCampaigns,
    actionFetchCampaignsSuccess,
    actionFetchCampaignsFailed,
    actionFetchMediaLibrary,
    actionFetchMediaLibrarySuccess,
    actionFetchMediaLibraryFailed,
    actionSetSelectedMedia,
    actionFetchScreensList,
    actionFetchScreensListSuccess,
    actionFetchScreensListFailed,
    actionSetSelectedScreen,
    actionSetSelectedDates,
    actionSetSelectedSlots,
    actionSetSelectedCampaignType,
    actionAddClusterSlots,
    resetCampaigns
} = campaignsReducer.actions;

export default campaignsReducer.reducer;
