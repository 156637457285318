import { TSelectCampaignTypeProps } from "./SelectCampaignType.types";
import styles from './SelectCampaignType.module.scss';
import {
    FontWeightEnum,
    Text,
    TextVariantsEnum,
  } from "../../../components/Text";
import Button from "../../../components/Button/Button";
import { ButtonVariantEnum, ButtonTextSizeEnum } from "../../../components/Button/button.types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routing/routes";
import { useCampaignsActions } from "../../../store/campaigns/useCampaignsActions";

export const SelectCampaignType: React.FC<TSelectCampaignTypeProps> = ({
    mainTitle,
    title1 = "My Screens",
    title2 = "Screen Hub",
    subtitle1,
    subtitle2,
    onCancel,
    loading
  }) => {
    const [selectedCampaignType, setSelectedCampaignType] = useState<string>('');

    const { actionSetSelectedCampaignType } = useCampaignsActions();

    const navigate = useNavigate();

    const handleOptionClick = (option: string) => {
        setSelectedCampaignType(option);
      };

      const onConfirm = () => {
        switch(selectedCampaignType) {
            case 'myScreens':
                actionSetSelectedCampaignType('myScreens');
                break;
            case 'screenHub':
                actionSetSelectedCampaignType('screenHub');
                break;
            default:
                break;
        }
        navigate(routes().createCampaign);
    };
    

    return (
        <div className={styles.selectCampaignTypeWrapper}>
            <Text
                variant={TextVariantsEnum.Display_xs}
                fontWeight={FontWeightEnum.Bold}
                className={styles.header}
            >
                {mainTitle}
            </Text>
            <div className={styles.separator} />
            <div className={styles.campaignTypesButtonSelector}>
                <div 
                    onClick={() => handleOptionClick('myScreens')}
                    className={`${styles.campaignTypeOption} ${selectedCampaignType === 'myScreens' ? styles.selected : styles.unselected}`}
                >
                    <div className={styles.title}>{title1}</div>
                    <div className={styles.subtitle}>{subtitle1}</div>
                </div>
                <div 
                    onClick={() => handleOptionClick('screenHub')}
                    className={`${styles.campaignTypeOption} ${selectedCampaignType === 'screenHub' ? styles.selected : styles.unselected}`}
                >
                    <div className={styles.title}>{title2}</div>
                    <div className={styles.subtitle}>{subtitle2}</div>
                </div>
            </div>
            <div className={styles.footer}>
                <Button
                    text={"Cancel"}
                    onClick={onCancel ? onCancel : () => {}}
                    variant={ButtonVariantEnum.secondary}
                    size={ButtonTextSizeEnum.sm}
                    width={"120px"}
                />
                <Button
                    text={"Next"}
                    onClick={onConfirm}
                    variant={ButtonVariantEnum.primary}
                    size={ButtonTextSizeEnum.sm}
                    width={"120px"}
                    disabled={!selectedCampaignType}
                />
            </div>
        </div>
    );
  };
