
import { LoginPage } from "../pages/Auth/Login/Login";
import { Campaign } from "../pages/campaign";
import { CreateCampaign } from "../pages/campaign/CreateCampaign/CreateCampaign.component";
import { Clusters } from "../pages/cluster";
import { CreateCluster } from "../pages/cluster/CreateCluster/CreateCluster.component";
import { Media } from "../pages/media";
import { Overview } from "../pages/overview";
import { routes } from "./routes";
import {Screens} from "../pages/screens";

export type TRouteData = {
    path: string;
    Component: React.FC<any>;
    isPrivate: boolean;
    navigationMenu?: boolean;
    children?: {
        path: string;
        Component: React.FC<any>;
    }[]
}


export const routesData: TRouteData[] = [
    { path: routes().login, Component: LoginPage, isPrivate: false, navigationMenu: false },
    { path: routes().home, Component: LoginPage, isPrivate: false, navigationMenu: false },
    { path: routes().overview, Component: Overview, isPrivate: true, navigationMenu: true },
    { path: routes().media, Component: Media, isPrivate: true, navigationMenu: true },
    { path: routes().campaign, Component: Campaign, isPrivate: true, navigationMenu: true },
    { path: routes().createCampaign, Component: CreateCampaign, isPrivate: true, navigationMenu: false },
    { path: routes().cluster, Component: Clusters, isPrivate: true, navigationMenu: true },
    { path: routes().createCluster, Component: CreateCluster, isPrivate: true, navigationMenu: false },
    { path: routes().screens, Component: Screens, isPrivate: true, navigationMenu: true },
]