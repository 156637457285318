import { Checkbox } from '../../../../components/Checkbox/Checkbox.component'
import { StatusBadge } from '../../../../components/StatusBadge/StatusBadge.component'
import { columnConfigType } from '../../../../components/Table/Table.types'
import { FontWeightEnum, Text, TextVariantsEnum } from '../../../../components/Text'
import { capitalize } from '../../../../utils/capitalize'
import styles from './SelectScreens.module.scss'
     
export const SelectScreenTableColumns = (
    selectedScreens: string[],
    onSelectScreen: (id: string) => void
): columnConfigType<any>[] => [
    {
        id: 'screen_name',
        name: 'Screen Name',
        cellRenderer : (data) => (
            <div className={styles.cellRenderer}>
                 <Checkbox
                    checked={selectedScreens.includes(data?.id)}
                    id={`table-header-checkbox-${data.id}`}
                    onClick = {() => onSelectScreen(data.id)}
                 />
                <Text
                variant = {TextVariantsEnum.Text_md}
                fontWeight={FontWeightEnum.Normal}
                color={'neutral-70'}
                onClick = {() => onSelectScreen(data.id)}
                >
                    {capitalize(data.name)}
                </Text>
            </div>
        )   
    },
        {
            id: 'status',
            name: 'Status',
            cellRenderer : (data) => (
                <div>
                    <Text 
                    variant = {TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                        <StatusBadge status={data?.status?.toLowerCase()} />
                    </Text>
                </div>
            )   
        },
        {
            id: 'location',
            name: 'Location',
            cellRenderer : (data) => (
                <div>
                    <Text 
                    variant = {TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                        {data.location}
                    </Text>
                </div>
            )   
        },
        {
            id: 'tags',
            name: 'Tags',
            cellRenderer : (data) => (
                <div>
                    <Text 
                    variant = {TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                        {data.tags.join(', ')}
                    </Text>
                </div>
            )   
        },
        {
            id: 'timing',
            name: 'Timing',
            cellRenderer : (data) => (
                <div>
                    <Text 
                    variant = {TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                        {data.timing}
                    </Text>
                </div>
            )   
        },
        // {
        //     id: 'default_media',
        //     name: 'Default Media',
        //     cellRenderer : (data) => (
        //         <div>
        //             <Text 
        //             variant = {TextVariantsEnum.Text_sm}
        //             fontWeight={FontWeightEnum.Normal}>
        //                 {data.default_media}
        //             </Text>
        //         </div>
        //     )   
        // },
    ]