import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router';


import styles from './BuilderNameSection.module.scss';
import { TBuilderNameSectionProps, builderNameSectionMode } from './BuilderNameSection.types';
import { SvgIcon } from '../../components/SvgIcon';

export const BuilderNameSection: FC<TBuilderNameSectionProps> = ({

  inputValue,
  inputPlaceholder,
  loading,
  mode,
  onInputChange,
  confirmChanges,
  externalClass,
  previewMode,
  additionFields,
  withoutTitleField,
  validation,
}) => {
  const { pathname } = useLocation();
  const inputGhostRef = useRef<any>(null);

  const [isInputActive, setIsInputActive] = useState(false);
  const [inputWidth, setInputWidth] = useState(10);
  const [editModeInputValue, setEditModeInputValue] = useState(
    inputValue ? inputValue : ''
  );
  const [headerEditModeDisabled, setHeaderEditModeDisabled] = useState(false);


  useEffect(() => setEditModeInputValue(inputValue), [inputValue]);

  useEffect(() => {
    setInputWidth(
      !inputGhostRef?.current?.clientWidth
        ? 10
        : inputGhostRef?.current?.clientWidth
    );
  }, [
    isInputActive,
    inputValue,
    editModeInputValue,
    loading,
    inputGhostRef?.current?.clientWidth,
  ]);

//   useEffect(() => {
//     const idPath = pathname.substring(pathname.lastIndexOf('/') + 1);
//     if (
//       idPath !== 'new' &&
//       idPath !== ':id' 
//     ) {
//       setHeaderEditModeDisabled(true);
//     }

//   }, []);

  const getAppropriateModeInputValue = (ghost: boolean) => {
    if (mode === builderNameSectionMode.EDIT && isInputActive)
      return editModeInputValue?.length
        ? editModeInputValue
        : `${ghost ? inputPlaceholder : ''}`;

    return inputValue?.length ? inputValue : `${ghost ? inputPlaceholder : ''}`;
  };

  const handleInputChange = (value: string) => {
    if (previewMode) return;

    const newValue = validation ? validation(value) : value;

    if (mode === builderNameSectionMode.EDIT) {
      setEditModeInputValue(newValue);

      return;
    }

    onInputChange(newValue);
  };

  const discardChanges = () => {
    setIsInputActive(false);
    setEditModeInputValue(inputValue);
  };

  const handleChangesConfirm = () => {
    confirmChanges && confirmChanges(editModeInputValue);
    discardChanges();
  };
  return (
    <div
      className={classNames(
        styles.editBuilderNameSectionWrapper,
        externalClass
      )}
    >
      <div className={styles.builderHeader}>
        {/* <button id={'back_close_logo'} onClick={handleBackCloseClick}>
          <SvgIcon src={'icons/arrow.svg'} rotate={'90'} color={'gray-500'} />
        </button> */}
        {/* {loading ? (
          <Skeleton className={styles.inputPlaceholder} />
        ) : ( */}
          <div className={styles.inputContainer}>
            <p className={styles.inputGhost} ref={inputGhostRef}>
              {getAppropriateModeInputValue(true).replaceAll(' ', '.')}
            </p>
            
              {!withoutTitleField && (
                <input
                  className={classNames({
                    [styles.inputActive]: isInputActive,
                    [styles.editMode]: mode === builderNameSectionMode.EDIT,
                  })}
                  id="builder_name_input"
                  type="text"
                  value={getAppropriateModeInputValue(false)}
                  onFocus={() => setIsInputActive(true)}
                  placeholder={inputPlaceholder}
                  onBlur={(e) => onInputChange(e.target.value.trim())}
                  onChange={(e) => handleInputChange(e.target.value)}
                  autoComplete="off"
                  style={{ width: `${inputWidth}px` }}
                  disabled={headerEditModeDisabled}
                />
              )}
              {mode === builderNameSectionMode.EDIT &&
                isInputActive &&
                !!getAppropriateModeInputValue(false).length && (
                  <div className={styles.builderNameControls}>
                    <div
                      className={styles.confirmNameUpdate}
                      onClick={handleChangesConfirm}
                    >
                      <SvgIcon
                        src={'icons/arrow.svg'}
                        color="primary-700"
                        size={15}
                      />
                    </div>
                    <div
                      className={styles.discardNameUpdate}
                      onClick={discardChanges}
                    >
                      <SvgIcon
                        src={'icons/arrow.svg'}
                        color="gray-700"
                        size={15}
                      />
                    </div>
                  </div>
                )}
          </div>
        {/* )} */}
      </div>
      {process.env.REACT_APP_ENV_PLATFORM === 'dev' && <>{additionFields}</>}
    </div>
  );
};

