import { useRef, useState } from "react";
import { FileUploaderTypeEnum, MIME_TYPES } from "./FileUploader.constants";
import styles from "./FileUploader.module.scss";
import { TFileUploaderProps } from "./FileUploader.types";
import { FontWeightEnum, Text, TextVariantsEnum } from "../Text";
import { SvgIcon } from "../SvgIcon";

export const FileUploader: React.FC<TFileUploaderProps> = ({
  handleFileUpload,
  file
}) => {
  const invisibleInputRef = useRef<any>(null);

  return (
    <div className={styles.uploaderWrapper}>
      <input
        className={styles.invisibleInput}
        type="file"
        accept={MIME_TYPES[FileUploaderTypeEnum.VIDEO].join(",")}
        name="invisible_input"
        id="indisible_input"
        onChange={(e) =>
            handleFileUpload && handleFileUpload(e.target.files?.[0])
        }
        ref={invisibleInputRef}
      />
      <div className={styles.uploadIcon}>
        <SvgIcon src="icons/upload.svg" />
      </div>
      <>

        {
          file && file.name ?  <div className={styles.deleteText}>
             <Text
              className={styles.removeFileWrapper}
              variant={TextVariantsEnum.Text_sm}
              fontWeight={FontWeightEnum.Bold}
              color="primary-700"
            >
              {file.name}
            </Text>
            <SvgIcon src={'icons/delete.svg'} size={18} color="error" onClick={() => {
                handleFileUpload && handleFileUpload(null)
              }}/>
          </div>
         : <div className={styles.uploadText}>
            {/* <Text
              className={styles.removeFileWrapper}
              variant={TextVariantsEnum.Text_sm}
              fontWeight={FontWeightEnum.Medium}
              color="neutral-50"
            >
              Drag & drop or
            </Text> */}
            <Text
              className={styles.removeFileWrapper}
              variant={TextVariantsEnum.Text_sm}
              fontWeight={FontWeightEnum.Bold}
              color="primary-700"
              onClick={() => {
                invisibleInputRef?.current?.click();
              }}
            >
              Click here
            </Text>
            <Text
              className={styles.removeFileWrapper}
              variant={TextVariantsEnum.Text_sm}
              fontWeight={FontWeightEnum.Medium}
              color="neutral-50"
            >
              to upload media files
            </Text>
          </div>
        }
      </>
    </div>
  );
};
