import React, {useEffect, useState} from 'react';
import TimeSlot from './TimeSlot.component';
import styles from './EventCalenderLarge.module.scss';
import {useCampaignsActions} from "../../store/campaigns/useCampaignsActions";

const hours = [
    "08 AM - 09 AM",
    "09 AM - 10 AM",
    "10 AM - 11 AM",
    "11 AM - 12 PM",
    "12 PM - 01 PM",
    "01 PM - 02 PM",
    "02 PM - 03 PM",
    "03 PM - 04 PM",
    "04 PM - 05 PM",
    "05 PM - 06 PM",
    "06 PM - 07 PM",
    "07 PM - 08 PM",
    "08 PM - 09 PM",
    "09 PM - 10 PM",
    "10 PM - 11 PM",
];

const dates = [
    "28 May 2024",
    "29 May 2024",
    "30 May 2024",
    "31 May 2024",
    "1 Jun 2024",
    "2 Jun 2024",
    "3 Jun 2024",
    "4 Jun 2024",
    "5 Jun 2024",
    "6 Jun 2024",
    "7 Jun 2024",
    "8 Jun 2024",
    "9 Jun 2024",
    "10 Jun 2024",
    "11 Jun 2024",
    "12 Jun 2024",
    "13 Jun 2024",
    "14 Jun 2024",
    "15 Jun 2024",
    "16 Jun 2024",
    "17 Jun 2024",
    "18 Jun 2024",
    "19 Jun 2024",
    "20 Jun 2024",
    "21 Jun 2024",
    "22 Jun 2024",
    "23 Jun 2024",
    "24 Jun 2024",
    "25 Jun 2024",
];

const EventCalenderLarge: React.FC = () => {
    const { actionAddClusterSlots } = useCampaignsActions();
    const [selectedTimestamps, setSelectedTimestamps] = useState<number[]>([]);
    const [isDragging, setIsDragging] = useState(false);

    const handleSelect = (row: string, column: string) => {
        setSelectedTimestamps(prevTimestamps => {
            // Generate timestamp for the selected cell
            const [day, month] = row.split(' ').slice(0, 2);
            const [startHour, endHour] = column.split(' ')[0].split(':');
            const date = new Date(`2024-${month} ${day} ${startHour}:00:00`);
            const timestamp = date.getTime() / 1000;

            // Toggle timestamp in the list
            const isSelected = prevTimestamps.includes(timestamp);
            const newTimestamps = isSelected
                ? prevTimestamps.filter(ts => ts !== timestamp)
                : [...prevTimestamps, timestamp];

            return newTimestamps;
        });
    };

    const handleMouseDown = (row: string, column: string) => {
        setIsDragging(true);
        handleSelect(row, column);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseEnter = (row: string, column: string) => {
        if (isDragging) {
            handleSelect(row, column);
        }
    };

    const isCellSelected = (row: string, column: string) => {
        // Check if the cell is selected based on the timestamp
        const [day, month] = row.split(' ').slice(0, 2);
        const [startHour, endHour] = column.split(' ')[0].split(':');
        const date = new Date(`2024-${month} ${day} ${startHour}:00:00`);
        const timestamp = date.getTime() / 1000;
        return selectedTimestamps.includes(timestamp);
    };

    useEffect(() => {
        actionAddClusterSlots(selectedTimestamps);
    }, [selectedTimestamps]);

    return (
        <div
            className={styles.board}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
        >
            <div className={styles.times}>
                {[""].concat(hours).map((hour, index) => (
                    <div
                        key={index}
                        className={`${styles.timeSlot} ${index === 0 ? styles.firstTimeSlot : ''}`}
                    >
                        {hour}
                    </div>
                ))}
            </div>
            <div className={styles.grid}>
                {dates.map((date, dateIndex) => (
                    <div key={dateIndex} className={styles.column}>
                        <div className={styles.date}>{date.split(' ')[0]} {date.split(' ')[1]}</div>
                        {hours.map((hour, hourIndex) => (
                            <TimeSlot
                                key={hourIndex}
                                row={date}
                                column={hour}
                                isSelected={isCellSelected(date, hour)}
                                onSelect={() => handleSelect(date, hour)}
                                onMouseDown={() => handleMouseDown(date, hour)}
                                onMouseEnter={() => handleMouseEnter(date, hour)}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EventCalenderLarge;
