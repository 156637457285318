import React from 'react';
import styles from './TimeSlot.module.scss';

interface TimeSlotProps {
    row: string;
    column: string;
    isSelected: boolean;
    onSelect: () => void;
    onMouseDown: () => void;
    onMouseEnter: () => void;
}

const TimeSlot: React.FC<TimeSlotProps> = ({ row, column, isSelected, onSelect, onMouseDown, onMouseEnter }) => {
    return (
        <div
            className={`${styles.timeSlot} ${isSelected ? styles.selected : ''}`}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
            onClick={onSelect}
        >
            {/* Add any content for the TimeSlot here if needed */}
        </div>
    );
};

export default TimeSlot;
