import classnames from 'classnames';
import { FontWeightEnum, Text, TextVariantsEnum } from '../Text';
import styles from './Table.module.scss'
import Button from '../Button/Button';
import { ButtonVariantEnum } from '../Button/button.types';
import { TableComponentType } from './Table.types';
import { TablePagination } from '../TablePagination/TablePagination.component';
import { Checkbox } from '../Checkbox/Checkbox.component';
import { Spinner } from '../Spinner/Spinner.component';

export const Table: TableComponentType = ({
    className,
    columns,
    data,
    sortedBy,
    reverseSort,
    totalRecords = 0,
    startRecord = 0,
    recordsPerPage = 10,
    isLoading,
    emptyState,
    onSortClick,
    onPaginationClick,
    withoutPagination,
    immutableRecordPerPage,
    isScrollable,
    paddings,
    onScroll,
    fixedColumns,
    scrollPosition,
    cohortTable,
    maxValueLength,
    tableHeading,
    tableHeadingButton,
}) => {

  const handleSortClick = (id: string) => {
    if (!onSortClick) return;

    if (id === sortedBy) {
      onSortClick(id, !reverseSort);
      return;
    }

    onSortClick(id, false);
  };
  
  let heading = tableHeading;
  if (typeof tableHeading === 'string') {
    heading = (
      <Text
        variant={TextVariantsEnum.Text_md}
        fontWeight={FontWeightEnum.Semibold}
        color="neutral-50"
        className={styles.tableHeading}
      >
        {tableHeading}
      </Text>
    );
  }

  if (!isLoading && !data?.length && emptyState) return <>{emptyState}</>;
  else {
    const padding = tableHeadingButton ? '10px' : '16px';
    return (
      <div className={classnames(styles.table, className)}>
        <div
          className={classnames({ [`${styles.tableScroll}`]: isScrollable})}
          onScroll={onScroll}
        >
          {tableHeading && (
            <div
              className={styles.tableHeadingContainer}
              style={{ padding:` ${padding}px 20px` }}
            >
              {heading}
              {tableHeadingButton && (
                <Button
                  icon={tableHeadingButton.icon }
                  key={tableHeadingButton.text}
                //   id={tableHeadingButton.id}
                  size="md"
                  text={tableHeadingButton.text}
                  variant={
                    tableHeadingButton.variant || ButtonVariantEnum.primary
                  }
                  onClick={tableHeadingButton.onClick}
                  disabled={tableHeadingButton.disabled}
                />
              )}
            </div>
          )}

          <table>
            <thead>
              <tr className={styles.header_row}>
                {columns?.map((column, index) => {
                  return (
                    <th
                      key={column.id}
                      className={classnames('', {
                        [styles.sortedColumn]: sortedBy === column.id,
                        // [column.thClassName]: column.thClassName,
                        [styles.customheaderPadding]: paddings,
                        [styles.customheaderWidth]:
                        totalRecords && totalRecords > 6 && cohortTable,
                      })}
                      style={{ width: column?.width }}
                    >
                      <div
                        className={`${styles.th_content} ${
                          fixedColumns?.includes(index) && scrollPosition && scrollPosition > 10
                            ? styles.fixedColumns
                            : ''
                        }`}
                      >
                        {/* {isLoading ? (
                          <Spinner />
                        ) : ( */}
                            <>
                            {column.withCheckbox && (
                              <Checkbox
                              checked={column?.isAllRowChecked  || false}
                              id={`table-header-checkbox-${column.id}`}
                              onClick={column.onCheckBoxClick}
                              />
                            )}
                          
                              <Text
                                variant={TextVariantsEnum.Text_sm}
                                fontWeight={FontWeightEnum.Bold}
                                color="neutral-50"
                                tooltip={column?.tooltip}
                              >
                                {column.name}
                              </Text>
                              </>
                        {/* )} */}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {isLoading
                ? new Array(5).fill('').map((_, i) => (
                    <tr key={i}>
                      {columns?.map((column) => (
                        <td key={`${i}_${column.id}`}>
                          {/* <Skeleton /> */}
                        </td>
                      ))}
                    </tr>
                  ))
                : data?.map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      className={`${paddings ? styles.customPadding : ''}`}
                    >
                      {columns?.map((column, index) => (
                        <td
                          key={`${rowIndex}_${column.id}`}
                          // style={{ padding: paddings ? paddings : '0 14px' }}
                          className={`${
                            paddings ? styles.customtdPadding : ''
                          } ${
                            fixedColumns?.includes(index) && scrollPosition && scrollPosition > 10
                              ? styles.frozenColumnBody
                              : ''
                          } ${maxValueLength && maxValueLength > 5 ? styles.customsizeSix : ''}`}
                        >
                          {column.cellRenderer(row, rowIndex, column)}
                        </td>
                      ))}
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
        {!withoutPagination && (
          <TablePagination
            totalRecords={totalRecords}
            startRecord={startRecord}
            recordsPerPage={recordsPerPage}
            onPaginationClick={onPaginationClick || (() => null)}
            immutableRecordPerPage={immutableRecordPerPage}
          />
        )}
      </div>
    );
  }
};
