import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionGetMediaStream, actionResetMediaUploadData, actionSetMediaUploadData, actionUploadMedia } from './media.reducer';

export const useMediaActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
        actionGetMediaStream,
        actionUploadMedia,
        actionSetMediaUploadData,
        actionResetMediaUploadData
    },
    dispatch
  );
};