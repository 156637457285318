import React from "react";
import {OtpPair} from "./OtpPair.types";
import styles from "./otpPair.module.scss";
import {FontWeightEnum, Text, TextVariantsEnum} from "../Text";

export const OtpPairComponent: React.FC<OtpPair> = ({
        title,
        subtitle,
        className,
        onAdd,
        onCancel,
    }) => {

    return (
        <div className={styles.otpPairWrapper}>
            <Text
                variant={TextVariantsEnum.Display_xs}
                fontWeight={FontWeightEnum.Bold}
                className={styles.header}
            >
                {title}
            </Text>
            <div className={styles.separator}/>
            <div className={styles.campaignTypesButtonSelector}>
                <Text
                    variant={TextVariantsEnum.Text_md}
                    fontWeight={FontWeightEnum.Normal}
                    // className={styles.header}
                >
                    {subtitle}
                </Text>
                <div className={styles.otpInputWrapper}>
                    {Array(4).fill("").map((_, index) => (
                        <input
                            key={index}
                            type="text"
                            maxLength={1}
                            className={styles.otpInput}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};