import { LayoutProvider } from "./layout/LayoutProvider/LayoutProvider";
import { Routes, Route, useLocation } from 'react-router-dom'
import { routesData } from "./routing/routesData";
import { LayoutConsumer } from "./layout/LayoutConsumer/LayoutConsumer";

export const App = () => {
    const location = useLocation()
    return (
        <LayoutProvider>
            <Routes location={location}>
                {
                    routesData.map(route => {
                        const { path, Component, children, ...rest } = route;
                        return (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <LayoutConsumer
                                        {...rest}
                                        path={path}
                                    >
                                        <Component />
                                    </LayoutConsumer>
                                }
                            >
                                {
                                    children && children.map(child => {
                                        const { path, Component } = child;
                                        return (
                                            <Route
                                                key={path}
                                                path={path}
                                                element={<Component />}
                                            />
                                        )
                                    })
                                }
                            </Route>
                        )
                    })
                }
            </Routes>
        </LayoutProvider>
    );
}; 
