import React from 'react';
import classNames from 'classnames';

import { TTextProps } from './text.types';

import styles from './text.module.scss';

const htmlTagMapping = {
  Display_2xl: 'h1',
  Display_xl: 'h2',
  Display_lg: 'h3',
  Display_md: 'h4',
  Display_sm: 'h5',
  Display_xs: 'h6',
  Header: 'p',
  Text_xl: 'p',
  Text_lg: 'p',
  Text_md: 'p',
  Text_sm: 'p',
  Text_xs: 'p',
};

export const TextComponent: React.FC<TTextProps> = ({
  id,
  align,
  className,
  color,
  dots,
  fontWeight,
  style,
  textTransform,
  variant,
  onClick,
  tooltip,
  tooltipPosition = 'top',
  isUnderlinedText = false,
  children,
}) => {
  const HtmlTag: any = htmlTagMapping[variant];
  const tooltipProp = tooltip ?? {};


  const textClass = classNames(
    className,
    styles.text, {
      [styles[`text_type_${variant}`]]: variant,
      [styles[`text_fontWeight_${fontWeight}`]]: fontWeight,
      [styles[`text_textTransform_${textTransform}`]]: textTransform,
      [styles[`text_align_${align}`]]: align,
      [styles[`text_dots`]]: dots,
      [styles[`text_cursor`]]: tooltip || onClick,
      [styles[`text_underline`]]: isUnderlinedText
  });

  return (
    <>
      <HtmlTag
        className={textClass}
        onClick={onClick}
        style={{ color: `var(--${color})`, ...style }}
        id={id}
        {...tooltipProp}
      >
        {children}
      </HtmlTag>
    </>
  );
};

