import React from 'react';

// import { colorType } from '@typings/color';

export enum TextVariantsEnum {
  Display_2xl = 'Display_2xl',
  Display_xl = 'Display_xl',
  Display_lg = 'Display_lg',
  Display_md = 'Display_md',
  Display_sm = 'Display_sm',
  Display_xs = 'Display_xs',
  Header = 'Header',
  Text_xl = 'Text_xl',
  Text_lg = 'Text_lg',
  Text_md = 'Text_md',
  Text_sm = 'Text_sm',
  Text_xs = 'Text_xs',
}

export enum FontWeightEnum {
  Normal = 'Normal',
  Medium = 'Medium',
  Semibold = 'Semibold',
  Bold = 'Bold',
}

export type TTextProps = {
  id?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  className?: string;
  color?: string;
  dots?: boolean;
  fontWeight?: FontWeightEnum;
  style?: React.CSSProperties;
  textTransform?: 'lowercase' | 'uppercase' | 'capitalize';
  variant: TextVariantsEnum;
  onClick?: () => void;
  tooltip?: { 'data-tip'?: string; 'data-for'?: string };
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  isUnderlinedText?: boolean;
  children?: any;
};