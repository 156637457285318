export const timeSlots = [
    // { label: "06:00 AM - 07:00 AM", value: "06" },
    // { label: "07:00 AM - 08:00 AM", value: "07" },
    // { label: "08:00 AM - 09:00 AM", value: "08" },
    { label: "09:00 AM - 10:00 AM", value: "09" },
    { label: "10:00 AM - 11:00 AM", value: "10" },
    { label: "11:00 AM - 12:00 PM", value: "11" },
    { label: "12:00 PM - 01:00 PM", value: "12" },
    { label: "01:00 PM - 02:00 PM", value: "13" },
    { label: "02:00 PM - 03:00 PM", value: "14" },
    { label: "03:00 PM - 04:00 PM", value: "15" },
    { label: "04:00 PM - 05:00 PM", value: "16" },
    { label: "05:00 PM - 06:00 PM", value: "17" },
    { label: "06:00 PM - 07:00 PM", value: "18" },
    { label: "07:00 PM - 08:00 PM", value: "19" },
    { label: "08:00 PM - 09:00 PM", value: "20" },
    { label: "09:00 PM - 10:00 PM", value: "21" },
    { label: "10:00 PM - 11:00 PM", value: "22" },
    { label: "11:00 PM - 12:00 AM", value: "23" },
    // { label: "12:00 AM - 01:00 AM", value: "24" },
];
