import { addDays, format } from "date-fns";
import { DatePicker } from "../../../../components/DatePicker/DatePicker.component";
import styles from "./ScheduleCampaign.module.scss";
import Button from "../../../../components/Button/Button";
import { ButtonVariantEnum } from "../../../../components/Button/button.types";
import { timeSlots } from "../CreateCampaign.constants";
import classNames from "classnames";
import {
  FontWeightEnum,
  Text,
  TextVariantsEnum,
} from "../../../../components/Text";
import { useCampaignsActions } from "../../../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../../../store/campaigns/useCampaignsSelector";
import { Range } from "../../../../components/DatePicker/DatePicker.types";
import EventCalenderLarge from "../../../../components/EventCalenderLarge/EventCalenderLarge.component";

export const ScheduleCampaign = () => {
  const { actionSetSelectedDates, actionSetSelectedSlots } =
    useCampaignsActions();
  const { selectedDates, selectedSlots, SelectedCampaignType } = useCampaignsSelector();

  const onSelectTimeSlot = (timeSlot: string) => {
    if (selectedSlots.includes(timeSlot)) {
      actionSetSelectedSlots(selectedSlots.filter((slot) => slot !== timeSlot));
      return;
    }
    actionSetSelectedSlots([...selectedSlots, timeSlot]);
  };

  const handleDateChange = (date: Range) => {
    const startDate = format(date.startDate, "yyyy-MM-dd");
    const endDate = format(date.endDate, "yyyy-MM-dd");
    actionSetSelectedDates([startDate, endDate]);
  };

  return (
    <div className={styles.scheduleWrapper}>
      {SelectedCampaignType === 'myScreens' ? (
          <>
            <div className={styles.selectorWrapper}>
              <Text
                  variant={TextVariantsEnum.Text_xl}
                  fontWeight={FontWeightEnum.Bold}
              >
                Select Date
              </Text>
              <DatePicker
                  startDate={selectedDates.length ? new Date(selectedDates[0]) : addDays(new Date(), 1)}
                  endDate={selectedDates.length ? new Date(selectedDates[1]) : addDays(new Date(), 1)}
                  onChange={handleDateChange}
                  minDate={addDays(new Date(), 1)}
              />
            </div>
            <div className={styles.selectorWrapper}>
              <Text
                  variant={TextVariantsEnum.Text_xl}
                  fontWeight={FontWeightEnum.Bold}
              >
                Available Time Slot
              </Text>
              <div className={styles.slotWrapper}>
                {timeSlots.map((timeSlot) => (
                    <Button
                        variant={
                          selectedSlots.includes(timeSlot.value)
                              ? ButtonVariantEnum.primary
                              : ButtonVariantEnum.secondary
                        }
                        text={timeSlot.label}
                        onClick={() => onSelectTimeSlot(timeSlot.value)}
                        height={"52px"}
                        className={classNames({
                          [styles.selectedBtn]: selectedSlots.includes(timeSlot.value),
                        })}
                    />
                ))}
              </div>
            </div>
          </>
      ) : (
          <EventCalenderLarge/>
      )}
    </div>
  );
};
