import { TLayoutConsumerProps } from "./LayoutConsumer.types";
import { useLayout } from "../../hooks/useLayout";
import React, { useEffect } from "react";
import { auth, awaitFirebaseInitialization } from "../../config/firebase";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routing/routes";
import { Navbar } from "../../features/Navbar";
import { useLoginActions } from "../../store/login/useLoginActions";

export const LayoutConsumer: React.FC<TLayoutConsumerProps> = ({
  path,
  isPrivate,
  navigationMenu = false,
  children,
}) => {
  const navigate = useNavigate();
  useLayout(navigationMenu);
  const {actionSetUserData} = useLoginActions()

  useEffect(() => {
    (async () => {
      await awaitFirebaseInitialization();

      if (isPrivate && !auth.currentUser) {
        navigate(routes().login);
        sessionStorage.removeItem('userData')
      }else{
        const userData =sessionStorage.getItem('userData')
        if(userData){
          actionSetUserData(JSON.parse(userData))
        }

      }
    
    })();
  }, []);
  
  return (
    <div
      style={{
        backgroundColor: "#EDE7FD",
        height: "100vh",
        overflowY: "auto",
      }}
    >
      {navigationMenu && <Navbar />}
      <>{children}</>
    </div>
  );
};
