import React from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

import { TSvgIconProps } from './SvgIcon.types';

import styles from './SvgIcon.module.scss';

export const SvgIconComponent: React.FC<TSvgIconProps> = ({
  src,
  size = 24,
  color = 'inherit',
  rotate,
  className,
  style,
  onClick,
  defaultStoke,
  disabled,
  ...rest
}) => {
  const svgIconClass = classNames(
    styles.svgIcon,
    {
      [styles[`svgIcon_rotate_${rotate}`]]: rotate,
      [styles.defaultStroke]: defaultStoke,
    },
    className
  );

  const stroke = defaultStoke
    ? {}
    : {
        // stroke: `var(--${useCustomColor ? 'svgCustomColor' : color})`,
        stroke: `var(--${color})`,
      };
  const customStyles = {
    width: `${size}px`,
    height: `${size}px`,
    ...style,
    ...stroke,
    ...(disabled && {
      opacity: 0.5,
      cursor: 'not-allowed',
    }),
    ...(onClick &&
      !disabled && {
        cursor: 'pointer',
      }),
  };

  return (
    <ReactSVG
      src={src}
      className={classNames(svgIconClass, {
        // [styles.filledCustomColor]: useCustomColor && filledByCustomColor,
      })}
      onClick={disabled ? () => null : onClick}
      style={customStyles}
      {...rest}
    />
  );
};
