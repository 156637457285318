import { useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import styles from './DatePicker.module.scss'
import { Range, TDatePickerProps } from './DatePicker.types';
import { addDays } from 'date-fns';

export const DatePicker: React.FC<TDatePickerProps> = ({ 
    onChange, 
    startDate = addDays(new Date(), 1),
    endDate = addDays(new Date(), 1),
    minDate, 
    maxDate
}) => {
    const [dPickerStartDate, setDPickerStartDate] = useState<Date>(startDate)
    const [dPickerEndDate, setDPickerEndDate] = useState<Date>(endDate)

      const handleDateChange = (date: Range) => {
        onChange(date)
        setDPickerEndDate(date.endDate)
        setDPickerStartDate(date.startDate)
      }

    return (
        <div className={styles.datePickerContainer}>
            <DateRange
                showDateDisplay={false}
                showMonthArrow={false}
                onChange={(item: any) => handleDateChange(item.selection)}
                moveRangeOnFirstSelection={false}
                ranges={[{
                    startDate: dPickerStartDate,
                    endDate: dPickerEndDate,
                    key: 'selection'
                }]}
                minDate={minDate}
                maxDate={maxDate}
            />
        </div>
    )
}