import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Button from "../../components/Button/Button";
import {
    ButtonTextSizeEnum,
    ButtonVariantEnum,
} from "../../components/Button/button.types";
import { Spinner } from "../../components/Spinner/Spinner.component";
import { Table } from "../../components/Table/Table.component";
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text";
import { routes } from "../../routing/routes";
import { useClustersActions } from "../../store/cluster/useClusterActions";
import { useClustersSelector } from "../../store/cluster/useClusterSelector";
import { useLoginSelector } from "../../store/login/useLoginSelectors";
import { requestStatusEnum } from "../../store/store.types";
import styles from "./cluster.module.scss";
import { ClusterTableColumns } from "./cluster.config";
import { TClusterListData } from "../../store/cluster/cluster.types";

export const ClusterComponent = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<Array<TClusterListData>>([]);
  const [startRecord, setStartRecord] = useState(0);

  const { actionFetchClusters } = useClustersActions();
  const {
    clusterList: { status: clusterListStatus, data: clusterList , totalPages},
  } = useClustersSelector();
  const {
    userData: { ordId: orgId},
  } = useLoginSelector();

  useEffect(() => {
    if(orgId)
    actionFetchClusters({ page: startRecord, orgId });
  }, [orgId]);

  useEffect(() => {
    if (clusterListStatus === requestStatusEnum.SUCCESS) {
      setTableData(clusterList);
    }
  }, [clusterListStatus]);

  const onPaginationClick = (startRecord: number, recordsPerPage: number) => {
    actionFetchClusters({ page:  startRecord/20, orgId });
    setStartRecord(startRecord)
  }; 

  const onNameClick = (name: string) => {
    console.log("name", name);
  };  

  return (
    <div className={styles.clusterWrapper}>
      <div className={styles.headerWrapper}>
        <Text
          variant={TextVariantsEnum.Display_sm}
          fontWeight={FontWeightEnum.Bold}
          className={styles.header}
        >
          Clusters
        </Text>
        <Button
          icon={"./icons/plus.svg"}
          iconColor="white"
          iconSize={12}
          text={"Create Cluster"}
          onClick={() => navigate(routes().createCluster)}
          variant={ButtonVariantEnum.primary}
          size={ButtonTextSizeEnum.md}
        />
      </div>
      {clusterListStatus === requestStatusEnum.PROGRESS ? (
        <Spinner />
      ) : (
        <Table
          data={tableData}
          columns={ClusterTableColumns(onNameClick)}
          isLoading={false}
          totalRecords={totalPages*20}
          startRecord={startRecord}
          recordsPerPage={20}
          onPaginationClick={onPaginationClick}
          emptyState={"No Cluster Found"}
        />
      )}
    </div>
  );
};
