export const validateEmail = (email: string): boolean => {
    // Check if email contains domain and is a proper email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  export const validatePassword = (password: string): boolean => {
    // Check for at least one uppercase, one lowercase, one number, one symbol, and min 8 characters
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // return passwordRegex.test(password);
    return password.length >= 8
  };
  