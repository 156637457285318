import React from 'react';

export type TBuilderNameSectionProps = {
  inputPlaceholder: string;
  inputValue: string;
  loading: boolean;
  mode: builderNameSectionMode;
  onInputChange: (value: string) => void;
  sectionTitle?: string;
  confirmChanges?: (updatedValue: string) => void;
  dropdownOptions?: { value: string | number; label: string }[];
  dropdownSearchValue?: string;
  onDropdownOptionClick?: (value: string) => void;
  onDropdownSearchValueChange?: (value: string) => void;
  onTitleClick?: () => void;
  externalClass?: string;
  previewMode?: boolean;
  additionFields?: React.ReactChild;
  withoutTitleField?: boolean;
  validation?: (value: string) => string;
  onBackClick?: () => void;
  isDropdownEnabled?: boolean;
};

export enum builderNameSectionMode {
    EDIT ='edit',
    CREATE = 'create'
}