import { App } from './App';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client';
import "./index.scss"

const domNode = document.getElementById('root') && document.getElementById('root');
const root = domNode && createRoot(domNode);

root && root.render(
 <Provider store={store}> 
    <BrowserRouter>
        <App />
    </BrowserRouter>
</Provider>
);
