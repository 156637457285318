import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionFetchCampaigns,
  actionFetchMediaLibrary,
  actionSetSelectedMedia,
  resetCampaigns,
  actionFetchScreensList,
  actionSetSelectedScreen,
  actionSetSelectedDates,
  actionSetSelectedSlots,
  actionSetSelectedCampaignType,
    actionAddClusterSlots
 } from './campaigns.reducer';

export const useCampaignsActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
        actionFetchCampaigns,
        actionFetchMediaLibrary,
        actionSetSelectedMedia,
        resetCampaigns,
        actionFetchScreensList,
        actionSetSelectedScreen,
        actionSetSelectedDates,
        actionSetSelectedSlots,
        actionSetSelectedCampaignType,
        actionAddClusterSlots
    },
    dispatch
  );
};