import { api } from "..";

export const CampaignsService = {
    getCampaigns: (payload: {page: number}) =>
        api.get(`/public/campaigns?page=${payload.page}`).then((data) => data?.data),

    getMediaLibrary: (payload: {page: number; orgId: string}) =>
        api.get(`/public/media/list/org/${payload.orgId}/status/UN_VERIFIED/page/${payload.page}`).then((data) => data?.data),

    getScreensList: (payload: {page: number}) =>
        api.get(`/public/screen/state/ALL/city/ALL/page/${payload.page}`).then((data) => data?.data),
}