import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Button from "../../../components/Button/Button";
import { ButtonVariantEnum } from "../../../components/Button/button.types";
import { builderNameSectionMode } from "../../../features/BuilderNameSection/BuilderNameSection.types";
import { CreateHeader } from "../../../features/CreateHeader/CreateHeader.component";
import { routes } from "../../../routing/routes";
import { useClustersActions } from "../../../store/cluster/useClusterActions";
import styles from "./CreateCluster.module.scss";
import { SelectScreen } from "./SelectScreens/SelectScreens.component";
import { CreateClusterFilter } from "./CreateClusterFilter/CreateClusterFilter.component";
import { useCampaignsSelector } from "../../../store/campaigns/useCampaignsSelector";
import { useLoginSelector } from "../../../store/login/useLoginSelectors";
import { Spinner } from "../../../components/Spinner/Spinner.component";
import { useClustersSelector } from "../../../store/cluster/useClusterSelector";
import { requestStatusEnum } from "../../../store/store.types";
import { useCampaignsActions } from "../../../store/campaigns/useCampaignsActions";

export const CreateCluster: React.FC = () => {
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState("");

  const { resetClusters, actionCreateCluster } = useClustersActions();
  const { actionSetSelectedScreen } = useCampaignsActions();
  const { selectedScreens } = useCampaignsSelector();
  const {
    userData: { ordId: orgId },
  } = useLoginSelector();
  const { createClusterStatus } = useClustersSelector();

  useEffect(() => {
    return () => {
      resetClusters();
      actionSetSelectedScreen([])
    };
  }, []);

  useEffect(() => {
if(createClusterStatus === requestStatusEnum.SUCCESS){
  onCloseClick()
}
  },[createClusterStatus])

  const onInputChange = (value: string) => {
    setInputValue(value);
  };

  const onCloseClick = () => {
    navigate(routes().cluster);
  };

  const onPublish = () => {
    if(!inputValue || !selectedScreens.length) return;
    actionCreateCluster({
      orgId,
      name: inputValue,
      screens: selectedScreens,
    });
  };

  const footer = (
    <div className={styles.footer}>
      <Button
        variant={ButtonVariantEnum.primary}
        text={"Publish"}
        onClick={onPublish}
        height={"32px"}
        width={"78px"}
        disabled={!inputValue || !selectedScreens.length}
      />
    </div>
  );

  return (
        <div>
           {createClusterStatus === requestStatusEnum.PROGRESS && ( <div className={styles.loader}>
          <Spinner />
        </div>
           )}
          <CreateHeader
            headerText="Create Cluster"
            name={inputValue}
            setName={onInputChange}
            nameFieldPlaceholder={"Enter Cluster Name"}
            onCloseClick={onCloseClick}
            mode={builderNameSectionMode.CREATE}
            footer={footer}
          />
          <div className={styles.screensWrapper}>
            <CreateClusterFilter />
            <SelectScreen />
          </div>
        </div>
  );
};
