import React from 'react';
import classNames from 'classnames';

import styles from './Checkbox.module.scss';
import { SvgIcon } from '../SvgIcon';
import { FontWeightEnum, Text, TextVariantsEnum } from '../Text';
import { CheckboxProps } from './Checkbox.types';

export const Checkbox: React.FC<CheckboxProps> = ({
  className,
  checked,
  id,
  text,
  disabled,
  onClick,
}) => {
  const checkboxClass = classNames(
    styles.checkbox,
    {
      [styles.disabled]: disabled,
      [styles.checked]: checked,
    },
    className
  );

  return (
    <div className={checkboxClass}>
      <input
        type="checkbox"
        checked={checked}
        name={id}
        id={id}
        disabled={disabled}
        onChange={disabled ? () => null : () => onClick && onClick(id)}
      />
      <label htmlFor={id}>
        <div className={styles.checkbox_box}>
          <SvgIcon
            src={'icons/checkbox-v.svg'}
            size={12}
            color={disabled ? 'gray-300' : 'primary-600'}
          />
        </div>
        {text && (
          <Text
            variant={TextVariantsEnum.Text_sm}
            fontWeight={FontWeightEnum.Medium}
            color={'gray-700'}
          >
            {text}
          </Text>
        )}
      </label>
    </div>
  );
};
