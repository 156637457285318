import { useEffect, useState } from "react";
import { useCampaignsSelector } from "../../store/campaigns/useCampaignsSelector";
import { useLoginSelector } from "../../store/login/useLoginSelectors";
import { useCampaignsActions } from "../../store/campaigns/useCampaignsActions";
import { requestStatusEnum } from "../../store/store.types";
import { Spinner } from "../../components/Spinner/Spinner.component";
import styles from "./Media.module.scss";
import { MediaCard } from "../../components/MediaCard/MediaCard.component";
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text";
import {
  ButtonTextSizeEnum,
  ButtonVariantEnum,
} from "../../components/Button/button.types";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routing/routes";
import { TablePagination } from "../../components/TablePagination/TablePagination.component";
import { Modal } from "../../components/Modal";
import { UploadMedia } from "./UploadMedia/UploadMedia.component";
import { useMediaActions } from "../../store/media/useMediaActions";
import { useMediaSelector } from "../../store/media/useMediaSelector";

export const MediaComponent: React.FC = () => {
  const navigate = useNavigate();

  const [startRecord, setStartRecord] = useState(0);
  const [modalStep, setModalStep] = useState(1);
  const [isUploadModalVisible, setUploadModalVisible] = useState(false);
  const [isStreamModalVisible, setStreamModalVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const {actionGetMediaStream, actionSetMediaUploadData, actionUploadMedia, actionResetMediaUploadData} = useMediaActions()
  const {media: {data: mediaStream, status: mediaStreamStatus}, mediaDataUploaded} = useMediaSelector()
  const { actionFetchMediaLibrary } = useCampaignsActions();
  const {
    mediaLibrary: {
      data: mediaLibraryData,
      status: mediaLibraryStatus,
      totalPages,
    },
  } = useCampaignsSelector();

  const {
    userData: { ordId: orgId , userId},
  } = useLoginSelector();

  useEffect(() => {
    if(orgId)
    actionFetchMediaLibrary({ page: startRecord, orgId });
  }, [orgId]);

  // useEffect(() => {
  //   if(mediaStreamStatus === requestStatusEnum.SUCCESS){
  //     let base64DataUrl: any;
  //     (async () => {
  //       base64DataUrl = await blobToBase64(mediaStream);
  //     })()
  //     // const objectUrl = URL.createObjectURL(mediaStream);
  //     setVideoUrl(base64DataUrl)
  //   }
  // },[mediaStreamStatus])

  const onPaginationClick = (startRecord: number, recordsPerPage: number) => {
    actionFetchMediaLibrary({ page: startRecord, orgId });
    setStartRecord(startRecord);
  };

  const onClose = () => {
    setUploadModalVisible(false)
  }
  const onCloseStreamModal = () => {
    setStreamModalVisible(false)
  }

  const onClickMedia = (id: string) => {
    setStreamModalVisible(true)
   actionGetMediaStream({orgId,id: id})
  }

  function blobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

const handleMediaDetails = (field: string, value: string | File) => {
  actionSetMediaUploadData({[`${field}`]: value})
}

const onCancelUpload = () => {
  if(modalStep == 2) {
    setModalStep(1)
  }else{
    onClose()
  }
}

const onHandleUpload = () => {
  if(modalStep == 1) {
    setModalStep(2)
  }else{
    actionUploadMedia({
      ...mediaDataUploaded,
      orgId,
      userId
    })
    setUploadModalVisible(false)
    console.log('upload', mediaDataUploaded)
    actionResetMediaUploadData()
    setModalStep(1)
  }
}


  return (
    <>
    <div className={styles.libraryWrapper}>
      <div className={styles.headerWrapper}>
        <Text
          variant={TextVariantsEnum.Display_sm}
          fontWeight={FontWeightEnum.Bold}
          className={styles.header}
          >
          Media Library
        </Text>
        <Button
          icon={"./icons/plus.svg"}
          iconColor="white"
          iconSize={12}
          text={"Add Media"}
          onClick={() => setUploadModalVisible(true)}
          variant={ButtonVariantEnum.primary}
          size={ButtonTextSizeEnum.md}
          />
      </div>
      {mediaLibraryStatus === requestStatusEnum.PROGRESS ? (
        <Spinner />
      ) : (
        <div className={styles.mediaWrapper}>
          <div className={styles.filters}>Filters</div>
          <div className={styles.separator} />
          <div className={styles.mediaList}>
            {mediaLibraryData.map((mediaData, index) => (
              <MediaCard
              key={`${mediaData.name}-${index}`}
              mediaId={mediaData.id}
              mediaName={mediaData.name}
              mediaSrc={`data:image/jpeg;base64,${mediaData.thumbnail}`}
              mediaTags={mediaData.tags}
              status={mediaData.status}
              onClickMedia = {onClickMedia}
              />
            ))}
          </div>
          <div className={styles.separator} />
          <TablePagination
            totalRecords={totalPages * mediaLibraryData.length}
            startRecord={startRecord}
            recordsPerPage={10}
            onPaginationClick={onPaginationClick}
            />
        </div>
      )}
    </div>
    <Modal
        isVisible={isUploadModalVisible}
        onClose={onClose}
        className={styles.modalClass}
      >
        <UploadMedia
          onConfirm={onHandleUpload}
          title={modalStep == 1 ? 'Add Media': 'Add Media Details'}
          loading={false}
          onCancel={onCancelUpload}
          modelStep = {modalStep}
          handleMediaDetails={handleMediaDetails}
          mediaDataUploaded={mediaDataUploaded}
        />
      </Modal>
    <Modal
        isVisible={isStreamModalVisible}
        onClose={onCloseStreamModal}
        className={styles.videoModalClass}
      >
       <div>
         <div className={styles.videoWrapper}>
            {mediaStreamStatus === requestStatusEnum.PROGRESS ?  <Spinner /> : 
              <video className={styles.video} controls>
                <source src={mediaStream} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            }
            </div>
        </div>
      </Modal>
      </>
  );
};
