import { useEffect, useState } from "react";
import { Table } from "../../components/Table/Table.component";
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text";
import styles from "./campaign.module.scss";
import { useCampaignsActions } from "../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../store/campaigns/useCampaignsSelector";
import { requestStatusEnum } from "../../store/store.types";
import { TCampaignListData } from "../../store/campaigns/campaigns.types";
import { CampaignsTableColumns } from "./campaign.config";
import Button from "../../components/Button/Button";
import {
  ButtonTextSizeEnum,
  ButtonVariantEnum,
} from "../../components/Button/button.types";
import { useNavigate } from "react-router";
import { routes } from "../../routing/routes";
import { Spinner } from "../../components/Spinner/Spinner.component";
import { Modal } from "../../components/Modal";
import { Input } from "../../components/Input";
import { SelectCampaignType } from "./SelectCampaignType/SelectCampaignType.component";
import {useLoginSelector} from "../../store/login/useLoginSelectors";

export const CampaignComponent = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<Array<TCampaignListData>>([]);
  const [startRecord, setStartRecord] = useState<number>(0);
  const [isCreateCampaignModalVisible, setIsCreateCampaignModalVisible] = useState<boolean>(false);

  const { actionFetchCampaigns } = useCampaignsActions();
  const {
    campaignsList: { status: campaignsListStatus, data: campaignsList },
  } = useCampaignsSelector();
  const { userData } = useLoginSelector();

  useEffect(() => {
    actionFetchCampaigns({ page: startRecord });
  }, []);

  useEffect(() => {
    if (campaignsListStatus === requestStatusEnum.SUCCESS) {
      setTableData(campaignsList);
    }
  }, [campaignsListStatus]);

  const onPaginationClick = (startRecord: number, recordsPerPage: number) => {
    actionFetchCampaigns({ page: startRecord });
  };

  const onNameClick = (name: string) => {
    console.log("name", name);
  };

  console.log('user data => ', userData);

  const onCloseCreateCampaignModal = () => {
    setIsCreateCampaignModalVisible(false);
  }

  const handleCreateCampaignButton = () => {
    if(userData?.type === 'MERCHANT') setIsCreateCampaignModalVisible(true)
    else navigate(routes().createCampaign);
  };

  return (
    <div className={styles.campaignWrapper}>
      <div className={styles.headerWrapper}>
        <Text
          variant={TextVariantsEnum.Display_sm}
          fontWeight={FontWeightEnum.Bold}
          className={styles.header}
        >
          Campaigns
        </Text>
        <Button
          icon={"./icons/plus.svg"}
          iconColor="white"
          iconSize={12}
          text={"Create Campaign"}
          onClick={handleCreateCampaignButton}
          variant={ButtonVariantEnum.primary}
          size={ButtonTextSizeEnum.md}
        />
      </div>
      {campaignsListStatus === requestStatusEnum.PROGRESS ? (
        <Spinner />
      ) : (
        <Table
          data={tableData}
          columns={CampaignsTableColumns(onNameClick)}
          isLoading={false}
          totalRecords={tableData?.length}
          startRecord={startRecord}
          onPaginationClick={onPaginationClick}
          emptyState={"No Campaign Found"}
        />
      )}
          <Modal
              isVisible={isCreateCampaignModalVisible}
              onClose={onCloseCreateCampaignModal}
              className={styles.modalClass}
              customStyles={{ maxWidth: '500px', maxHeight: '415px' }}
          >
              <SelectCampaignType
                  mainTitle = {'Select Campaign Type'}
                  title1= {'My Screens'}
                  title2={'Screen Hub'}
                  subtitle1={'Lorem Ipsum 1'}
                  subtitle2={'Lorem Ipsum 2'}
                  onCancel={onCloseCreateCampaignModal}
                  loading={false}
              />
          </Modal>
    </div>
  );
};
