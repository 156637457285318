import { createAction, createSlice } from '@reduxjs/toolkit';
import { requestStatusEnum } from '../store.types';
import { TActionCreateClusterPayload, TActionGetClusterListPayload, TActionGetClusterListSuccess, TActionGetScreensListSuccess, TClusterReducer } from './cluster.types';


const initialState: TClusterReducer = {
    clusterList: {
        data: [],   
           totalPages: 0,
        status: requestStatusEnum.INITIAL
    },
    currentPage: 0,
    screensList: {
      data: [],
      totalPages: 0,
      status: requestStatusEnum.INITIAL
    },
    selectedScreens:[],
    selectedClusters: [],
    createClusterStatus: requestStatusEnum.INITIAL
};

const clustersReducer = createSlice({
  name: 'clusters',
  initialState,
  reducers: {
    actionFetchClusters(state, { payload }: TActionGetClusterListPayload) {
      state.clusterList.status = requestStatusEnum.PROGRESS;
    },
    actionFetchClustersSuccess(state, {payload}: TActionGetClusterListSuccess) {
      state.clusterList.status = requestStatusEnum.SUCCESS;
      state.clusterList.data = payload.data;
      state.clusterList.totalPages = payload.totalPages
    },
    actionFetchClustersFailed(state) {
      state.clusterList.status = requestStatusEnum.FAILED;
    },
    actionFetchScreensList(state, {payload}: TActionGetClusterListPayload) {
      state.screensList.status = requestStatusEnum.PROGRESS;
    },
    actionFetchScreensListSuccess(state, {payload}: TActionGetScreensListSuccess) {
      state.screensList.status = requestStatusEnum.SUCCESS;
      state.screensList.data = payload.data
      state.screensList.totalPages = payload.totalPages
    },
    actionFetchScreensListFailed(state) {
      state.screensList.status = requestStatusEnum.FAILED;
      state.screensList.data = []
    },
    actionSetSelectedScreen(state, {payload}) {
      state.selectedScreens = payload;
    },
    actionSetSelectedCluster(state, { payload }) {
      state.selectedClusters = payload;
    },
    actionCreateCluster(state, {payload}: TActionCreateClusterPayload){
      state.createClusterStatus = requestStatusEnum.PROGRESS
    },
    actionCreateClusterSuccess(state, {payload}){
      state.createClusterStatus = requestStatusEnum.SUCCESS
    },
    actionCreateClusterFailed(state){
      state.createClusterStatus = requestStatusEnum.FAILED
    },
    resetClusters() {
      return initialState;
    },
  },
})

export const {
  actionFetchClusters,
  actionFetchClustersSuccess,
  actionFetchClustersFailed,
    actionFetchScreensList,
    actionFetchScreensListSuccess,
    actionFetchScreensListFailed,
    actionSetSelectedScreen,
    resetClusters,
    actionCreateCluster,
    actionCreateClusterSuccess,
    actionCreateClusterFailed,
    actionSetSelectedCluster,
} = clustersReducer.actions;

export default clustersReducer.reducer

