export enum FileUploaderTypeEnum {
    IMAGE = 'image',
    VIDEO = 'video',
    DOCUMENT = 'document',
    CSV = 'csv',
  }
  
  export enum ImgMimeTypesEnum {
    SVG = 'image/svg+xml',
    JPEG = 'image/jpeg',
    GIF = 'image/gif',
    PNG = 'image/png',
  }
  
  export enum VideoMimeTypesEnum {
    MP4 = 'video/mp4',
  }
  
  export enum DocumentMimeTypesEnum {
    PDF = 'application/pdf',
    TEXT = 'text/plain',
  }
  export enum CSVMimeTypesEnum {
    CSV = 'text/csv',
  }
  export const FILE_EXTENSIONS = {
    [FileUploaderTypeEnum.IMAGE]: {
      [ImgMimeTypesEnum.SVG]: 'SVG',
      [ImgMimeTypesEnum.JPEG]: 'JPEG',
      [ImgMimeTypesEnum.GIF]: 'GIF',
      [ImgMimeTypesEnum.PNG]: 'PNG',
    },
    [FileUploaderTypeEnum.VIDEO]: {
      [VideoMimeTypesEnum.MP4]: 'MP4',
    },
    [FileUploaderTypeEnum.DOCUMENT]: {
      [DocumentMimeTypesEnum.PDF]: 'PDF',
      [DocumentMimeTypesEnum.TEXT]: 'PLAIN',
    },
    [FileUploaderTypeEnum.CSV]: {
      [CSVMimeTypesEnum.CSV]: 'CSV',
    },
  };
  
  export const MIME_TYPES = {
    [FileUploaderTypeEnum.IMAGE]: [
      ImgMimeTypesEnum.SVG,
      ImgMimeTypesEnum.JPEG,
      ImgMimeTypesEnum.GIF,
      ImgMimeTypesEnum.PNG,
    ],
    [FileUploaderTypeEnum.VIDEO]: [VideoMimeTypesEnum.MP4],
    [FileUploaderTypeEnum.DOCUMENT]: [
      DocumentMimeTypesEnum.PDF,
      DocumentMimeTypesEnum.TEXT,
    ],
    [FileUploaderTypeEnum.CSV]: [CSVMimeTypesEnum.CSV],
  };