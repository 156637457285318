import styles from './SelectScreens.module.scss'
import { useEffect, useState } from "react";
import { TSelectScreenProps } from './SelectScreens.types';
import { useCampaignsActions } from '../../../../store/campaigns/useCampaignsActions';
import { useCampaignsSelector } from '../../../../store/campaigns/useCampaignsSelector';
import { requestStatusEnum } from '../../../../store/store.types';
import { Spinner } from '../../../../components/Spinner/Spinner.component';
import { Table } from '../../../../components/Table/Table.component';
import { SelectScreenTableColumns } from './SelectScreens.config';


export const SelectScreen:React.FC<TSelectScreenProps> = ({}) => {
    const [tableData, setTableData] = useState<Array<any>>([])
    const [startRecord, setStartRecord] = useState(0)

    const {actionSetSelectedScreen, actionFetchScreensList} = useCampaignsActions();
    const {screensList: {data: screensList, status: screensListStatus, totalPages}, selectedScreens } = useCampaignsSelector();

    useEffect(() => {
        actionFetchScreensList({page: startRecord})
     },[])

     useEffect(() => {
        if(screensListStatus === requestStatusEnum.SUCCESS){
            setTableData(screensList)
        }
     },[screensListStatus])

    const onPaginationClick = (startRecord: number, recordsPerPage: number) => {
        actionFetchScreensList({page: startRecord/20})
        setStartRecord(startRecord)
    }
    
    const onSelectScreen = (selectedId: string) => {
        if(selectedScreens.includes(selectedId)){
            actionSetSelectedScreen(selectedScreens.filter(id => id !== selectedId))
            return;
        }
        actionSetSelectedScreen([...selectedScreens, selectedId])
    }

    return (
        <>{screensListStatus === requestStatusEnum.PROGRESS ? <Spinner /> : <div className={styles.ScreensWrapper}>
        <Table
                data = {tableData}
                columns={SelectScreenTableColumns( selectedScreens, onSelectScreen)}
                isLoading={false}
                totalRecords={totalPages*20}
                startRecord={startRecord}
                recordsPerPage={20}
                onPaginationClick={onPaginationClick}
                emptyState={'No Screen Found'}
                className={styles.screenTable}
            />
            </div>
            }
        </>
    )
}