import { MediaCard } from "../../../../components/MediaCard/MediaCard.component";
import { useCampaignsActions } from "../../../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../../../store/campaigns/useCampaignsSelector";
import styles from "./SelectMedia.module.scss";
import { requestStatusEnum } from "../../../../store/store.types";
import { Spinner } from "../../../../components/Spinner/Spinner.component";
import { useEffect, useState } from "react";
import { useLoginSelector } from "../../../../store/login/useLoginSelectors";

export const SelectMedia: React.FC<{}> = () => {
  const [startRecord, setStartRecord] = useState(0)

  const { actionSetSelectedMedia, actionFetchMediaLibrary } = useCampaignsActions();
  const {
    mediaLibrary: { data: mediaLibraryData, status: mediaLibraryStatus },
    selectedMedia,
  } = useCampaignsSelector();

  const {userData: {ordId: orgId}} = useLoginSelector()

  useEffect(() => {
    if(orgId)
    actionFetchMediaLibrary({page: startRecord, orgId })
 },[orgId])

  const handleSelectMedia = (id: string) => {
    actionSetSelectedMedia(id);
  };

  return (<>
  {mediaLibraryStatus === requestStatusEnum.PROGRESS ? <Spinner /> : 
        <div className={styles.mediaWrapper}>
          {mediaLibraryData.map((mediaData, index) => (
            <MediaCard
              key={`${mediaData.id}-${index}`}
              mediaId={mediaData.id}
              mediaSrc={`data:image/jpeg;base64,${mediaData.thumbnail}`}
              mediaTags={mediaData.tags}
              selected={selectedMedia === mediaData.id}
              handleSelectMedia={handleSelectMedia}
            />
          ))}
        </div>
    }</>
  );
};
