import { columnConfigType } from "../../components/Table/Table.types"
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text"
import { TClusterListData } from "../../store/cluster/cluster.types"
import { capitalize } from "../../utils/capitalize"
import {TScreensListData} from "../../store/screens/screens.types";

export const ScreensTableColumns = (
    onNameClick: (name: string) => void
): columnConfigType<TScreensListData>[] => [
    {
        id: 'screen_name',
        name: 'Screen Name',
        cellRenderer : (data) => (
            <div>
                <Text
                    variant = {TextVariantsEnum.Text_md}
        fontWeight={FontWeightEnum.Normal}
        color={'neutral-70'}
        onClick={() => onNameClick(data.name)}
>
{capitalize(data.name)}
</Text>
</div>
)
},
{
    id: 'status',
        name: 'Status',
    cellRenderer : (data) => (
    <div>
        <Text
            variant = {TextVariantsEnum.Text_sm}
    fontWeight={FontWeightEnum.Normal}>
        {data.status}
        </Text>
        </div>
)
},
    {
        id: 'location',
        name: 'Location',
        cellRenderer : (data) => (
            <div>
                <Text
                    variant = {TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    {data.location}
                </Text>
            </div>
        )
    },
    {
        id: 'tags',
        name: 'Tags',
        cellRenderer : (data) => (
            <div>
                <Text
                    variant = {TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    {data.tags}
                </Text>
            </div>
        )
    },
    {
        id: 'timings',
        name: 'Timings',
        cellRenderer : (data) => (
            <div>
                <Text
                    variant = {TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    {data.timing}
                </Text>
            </div>
        )
    },
    {
        id: 'defaultMedia',
        name: 'Default Media',
        cellRenderer : (data) => (
            <div>
                <Text
                    variant = {TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    {data.defaultMedia}
                </Text>
            </div>
        )
    },
]