import React from 'react';
import { ButtonVariantEnum, TButtonProps } from './button.types';
import styles from './button.module.scss';
import classNames from 'classnames'
import { FontWeightEnum, Text, TextVariantsEnum } from '../Text';
import { SvgIcon } from '../SvgIcon';

const textSizeMap = {
    sm: TextVariantsEnum.Text_sm,
    md: TextVariantsEnum.Text_md,
    lg: TextVariantsEnum.Text_lg
}
const Button: React.FC<TButtonProps> = ({
    text,
    variant = ButtonVariantEnum.primary,
    size = 'sm',
    disabled = false,
    onClick,
    loading = false,
    className,
    width, 
    height,
    icon,
    iconSize = 16,
    iconColor = 'inheret',
    iconRotation
}) => {

    const style = {width, height}

    const buttonClass = classNames(styles.button,{
       [ styles[`button_variant_${variant}`]]: variant,
       [ styles[`button_size_${size}`]]: size,
       [ styles[`button_disabled`]]: disabled,
    },
    className
    )

    return (
        <button
            className={buttonClass}
            onClick={onClick}
            disabled={disabled}
            style={style}
         >
            {/* <div
        className={styles.loader}
        data-testid={`button-loader-${loading ? 'active' : 'inactive'}`}
      >
        <SvgIcon src={''} size={20} />
      </div> */}
      {icon && (
        <SvgIcon
          src={icon}
          size={iconSize}
          data-testid="button-icon"
          color={iconColor}
          disabled={disabled}
          rotate={iconRotation}
        />
      )}
      {text && (
        <Text variant={textSizeMap[size]} fontWeight={FontWeightEnum.Medium}>
          {text}
        </Text>
      )}
      {/* {loading && (
        <Spinner  />
      )} */}
        </button>
    );
};

export default Button;