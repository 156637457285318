import { createContext, useContext, useEffect } from "react"

export const LayoutContext = createContext<any>(null)

export const useLayout = (navigationMenu: boolean) => {
    const setNavigationMenuVisible = useContext(LayoutContext);

    useEffect(() => {
        setNavigationMenuVisible(navigationMenu)
    },[])

    return;
} 