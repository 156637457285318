import { api } from "..";

export const ClustersService = {
    getClusters: (payload: {orgId: string; page: number}) =>
        api.get(`/public/screen/cluster/org/${payload.orgId}/page/${payload.page}`).then((data) => data?.data),

    getScreensList: (payload: {page: number}) =>
        api.get(`/public/screen/state/ALL/city/ALL/page/${payload.page}`).then((data) => data?.data),

    createCluster: (payload: {orgId:string;
        name: string;
    screens: Array<string>}) => 
        api.post(`/public/screen/cluster/create`,payload).then((data) => data?.data),
}