import { createAction, createSlice } from '@reduxjs/toolkit';
import { TActionLoginWithEmailPayload, TLoginReducer } from './login.types';
import { requestStatusEnum } from '../store.types';
import { NAV_ITEM_NAME } from '../../features/Navbar/Navbar.utils';


const initialState: TLoginReducer = {
  form: {
    email: '',
    password: '',
    remember_me: false,
    status: requestStatusEnum.INITIAL
  },
  error: { email: '', password: '' },
  userData: {
    accountStatus: '',
    ordId: '',
    role: '',
    userId: '',
      type: '',
    name: '',
  },
  currentNav: NAV_ITEM_NAME.OVERVIEW
};

const loginReducer = createSlice({
  name: 'loginReducer',
  initialState,
  reducers: {
    actionSubmitLogin(state, { payload }: TActionLoginWithEmailPayload) {
      state.form.status = requestStatusEnum.PROGRESS;
    },
    actionSubmitLoginSuccess(state, {payload}) {
      state.form.status = requestStatusEnum.SUCCESS;
      state.userData = payload.data
    },
    actionSubmitLoginFailed(state) {
      state.form.status = requestStatusEnum.FAILED;
    },
    submitLoginError(state, { payload }) {
      state.form.status = requestStatusEnum.FAILED;
      state.error = payload;
    },
    // updateLoginInput(state, { payload }: TUpdateLoginInput) {
    //   state.form[payload.field] = payload.value;
    // },
    // updateLoginCheckbox(state, { payload }: TUpdateLoginCheckbox) {
    //   state.form.remember_me = payload;
    // },
    actionSetUserData(state, {payload}){
state.userData = payload
    },
    actionSetActiveNav(state, {payload}){
      state.currentNav = payload
    },
    resetLogin() {
      return initialState;
    },
  },
})

export const {
    actionSubmitLogin,
    actionSubmitLoginSuccess,
    actionSubmitLoginFailed,
    actionSetUserData,
    actionSetActiveNav
} = loginReducer.actions;

export default loginReducer.reducer

