import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { routes } from '../../routing/routes';
import styles from './CreateHeader.module.scss'
import { FontWeightEnum, Text, TextVariantsEnum } from '../../components/Text';
import { SvgIcon } from '../../components/SvgIcon';
import { BuilderNameSection } from '../BuilderNameSection/BuilderNameSection.component';
import { builderNameSectionMode } from '../BuilderNameSection/BuilderNameSection.types';
import { TCreateCampaignProps } from './CreateHeader.types';

export const CreateHeader: React.FC<TCreateCampaignProps> = ({
    headerText,
    children,
    name,
    nameFieldPlaceholder,
    setName,
    onCloseClick,
    nameValidation,
    footer,
    mode = builderNameSectionMode.CREATE
}) => {

    const onInputChange = (value: string) => {
        setName(value)
    }

return (
    <div className={styles.newCampaignHeader}>
        <Text
            variant={TextVariantsEnum.Text_xl}
            fontWeight={FontWeightEnum.Bold}
        >
            {headerText}
        </Text>
        <SvgIcon src='icons/arrow.svg' rotate={'180'} size={12} />
        <BuilderNameSection 
            inputPlaceholder={nameFieldPlaceholder} 
            onInputChange={onInputChange}
            inputValue={name}
            mode={mode}
            loading={false}
            withoutTitleField={false}
            />
        {children}
        <div className={styles.newCampaignHeaderRight}>
            {footer}
            <SvgIcon src={'icons/cross.svg'} size={16} className={styles.cross} onClick={onCloseClick} />
        </div>
    </div>
  );
};