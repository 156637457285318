import { TSelectScreenProps } from "./SelectScreenCluster.types";
import styles from './SelectScreenCluster.module.scss';
import { Table } from "../../../../components/Table/Table.component";
import { getTableColumns } from "./SelectScreenCluster.config";
import { useEffect, useState } from "react";
import { requestStatusEnum } from "../../../../store/store.types";
import { useCampaignsActions } from "../../../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../../../store/campaigns/useCampaignsSelector";
import { Spinner } from "../../../../components/Spinner/Spinner.component";
import { useClustersSelector } from "../../../../store/cluster/useClusterSelector";
import {useClustersActions} from "../../../../store/cluster/useClusterActions";
import {useLoginSelector} from "../../../../store/login/useLoginSelectors";

export const SelectScreen: React.FC<TSelectScreenProps> = ({}) => {
    const [tableData, setTableData] = useState<Array<any>>([]);
    const [startRecord, setStartRecord] = useState(0);

    const { actionSetSelectedScreen, actionFetchScreensList, resetCampaigns } = useCampaignsActions();
    const { actionFetchClusters, actionSetSelectedCluster, resetClusters } = useClustersActions();

    const { screensList: { data: screensList, status: screensListStatus, totalPages }, SelectedCampaignType, selectedScreens } = useCampaignsSelector();

    const { clusterList: { status: clusterListStatus, data: clusterList, totalPages: clustersTotalPages }, selectedClusters } = useClustersSelector();

    const {
        userData: { ordId: orgId},
    } = useLoginSelector();

    useEffect(() => {
        if(SelectedCampaignType === 'myScreens'){
            actionFetchScreensList({ page: startRecord });
        }
        else {
            actionFetchClusters({ page: startRecord, orgId });
        }
    }, []);

    useEffect(() => {
        if (screensListStatus === requestStatusEnum.SUCCESS && screensList?.length > 0) setTableData(screensList);
        if (clusterListStatus === requestStatusEnum.SUCCESS && clusterList?.length > 0) setTableData(clusterList);
    }, [screensListStatus, screensList, clusterListStatus, clusterList]);

    const onPaginationClick = (startRecord: number, recordsPerPage: number) => {
        if (screensListStatus === requestStatusEnum.SUCCESS && screensList?.length > 0) actionFetchScreensList({ page: startRecord / 20 });
        if (clusterListStatus === requestStatusEnum.SUCCESS && clusterList?.length > 0) actionFetchClusters({ page: startRecord / 20, orgId });
        setStartRecord(startRecord);
    };

    const onNameClick = (name: string) => {
        console.log("name", name);
    };

    const onSelectItem= (selectedId: string) => {
        if(SelectedCampaignType === 'myScreens') {
            if (selectedScreens.includes(selectedId)) {
                actionSetSelectedScreen(selectedScreens.filter(id => id !== selectedId));
                return;
            }
            actionSetSelectedScreen([...selectedScreens, selectedId]);
        }
        else if(SelectedCampaignType === 'screenHub') {
            if (selectedClusters.includes(selectedId)) {
                actionSetSelectedCluster(selectedClusters.filter(id => id !== selectedId));
                return;
            }
            actionSetSelectedCluster([...selectedClusters, selectedId]);
        }
    };

    const isScreen = SelectedCampaignType === 'myScreens';

    return (
        <>
            {isScreen ? (
                screensListStatus === requestStatusEnum.PROGRESS ? (
                    <Spinner />
                ) : (
                    <div className={styles.ScreensWrapper}>
                        <Table
                            data={tableData}
                            columns={getTableColumns({ isScreen, onNameClick, onSelectItem, selectedItems: selectedScreens })}
                            isLoading={false}
                            totalRecords={totalPages * 20}
                            startRecord={startRecord}
                            recordsPerPage={20}
                            onPaginationClick={onPaginationClick}
                            emptyState={'No Screen Found'}
                            className={styles.screenTable}
                        />
                    </div>
                )
            ) : (
                clusterListStatus === requestStatusEnum.PROGRESS ? (
                    <Spinner />
                ) : (
                    <Table
                        className={styles.screenTable}
                        data={tableData}
                        columns={getTableColumns({ isScreen, onNameClick, onSelectItem, selectedItems: selectedClusters })}
                        isLoading={false}
                        totalRecords={clustersTotalPages * 20}
                        startRecord={startRecord}
                        recordsPerPage={20}
                        onPaginationClick={onPaginationClick}
                        emptyState={'No Cluster Found'}
                    />
                )
            )}
        </>
    );
};
